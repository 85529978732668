import React, { useEffect, useState } from 'react'
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { Breadcrumbs, Grid, Tabs, RadioGroup, Typography, Button, IconButton, Divider, Paper, Collapse, DialogContent, Dialog, TextField, FormLabel, FormControl, FormControlLabel, Radio, Autocomplete, TablePagination, Box, Checkbox } from '@mui/material'
import theme, { textClasses, buttonClasses, inputClasses } from "../../../static/styles/theme"
import { styled } from "@mui/material/styles";
import GenericLoader from '../../Components/Generic/GenericLoader';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { useDispatch, useSelector } from 'react-redux';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import $, { data } from "jquery";
import GenericGraphTimer from './GenericGraphTimer';
import TypeAndSearch from '../../Components/TypeAndSearch';
import { useDebounce } from '../../../utils/hooks';
import { component } from '../MainDashboard/utils';
import { getSkuTrendingGraphData } from '../../../redux/actions';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const timerOptions = [
    { value: 'last7Days', label: 'Last 7 days' },
    { value: 'last15Days', label: 'Last 15 days' },
    { value: 'lastYear', label: 'Last Year' },
    // { value: 'custom', label: 'Custom' },
];

const SkuTrendingGraph = ({ skuId }) => {
    const dispatch = useDispatch();
    const [lineGraphData, setLineGraphData] = useState([])
    const [dateRange, setDateRange] = useState({ fromDate: null, toDate: null })
    const [selectedDays, setSelectedDays] = useState(timerOptions[0]?.value)
    const [loader, setLoader] = useState(false);
    var chart;
    // console.log("date", dateRange)


    function lineChart() {
        var chart = am4core.create("line", am4charts.XYChart);

        chart.plotContainer.stroke = am4core.color("#aaa");
        chart.plotContainer.strokeOpacity = 1;
        chart.paddingRight = 40;

        // Add data
        chart.data = lineGraphData?.map((item) => ({
            date: item.date,
            currentStock: item.currentStock,
            gateInCount: item.gateInCount,
            gateOutCount: item.gateOutCount,
        }));

        // Create axes
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.title.text = "Time Period";
        dateAxis.title.fontWeight = "bold";

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.extraMax = 0.05;
        valueAxis.title.text = "Inventory Level";
        valueAxis.title.fontWeight = "bold";

        // Create gateInCount axis
        var lineSeries2 = chart.series.push(new am4charts.LineSeries());
        lineSeries2.dataFields.valueY = "gateInCount";
        lineSeries2.dataFields.dateX = "date";
        // lineSeries2.name = "gateInCount";
        lineSeries2.strokeWidth = 3;
        lineSeries2.fill = am4core.color("#fff");
        lineSeries2.stroke = am4core.color("blue");
        lineSeries2.smoothing = "monotoneX";
        lineSeries2.tooltipText = "gateInCount: {valueY}"; // Tooltip for gateInCount


        // Create series
        var lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.valueY = "currentStock";
        lineSeries.dataFields.dateX = "date";
        // lineSeries.name = "currentStock";
        lineSeries.strokeWidth = 3;
        lineSeries.fill = am4core.color("#fff");
        lineSeries.stroke = am4core.color("#3E621E");
        lineSeries.smoothing = "monotoneX";
        lineSeries.tooltipText = "currentStock: {valueY}"; // Tooltip for currentStock


        var lineSeries1 = chart.series.push(new am4charts.LineSeries());
        lineSeries1.dataFields.valueY = "gateOutCount";
        lineSeries1.dataFields.dateX = "date";
        // lineSeries1.name = "Outward";
        lineSeries1.strokeWidth = 3;
        lineSeries1.fill = am4core.color("#fff");
        lineSeries1.stroke = am4core.color("red");
        lineSeries1.smoothing = "monotoneX";
        lineSeries1.tooltipText = "gateOutCount: {valueY}"; // Tooltip for gateOutCount


        // Add simple bullet
        var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
        bullet.circle.stroke = am4core.color("#7AEDE3");
        bullet.circle.strokeWidth = 1;
        bullet.circle.propertyFields.fill = "color";

        var bullet = lineSeries1.bullets.push(new am4charts.CircleBullet());
        bullet.circle.stroke = am4core.color("#7AEDE3");
        bullet.circle.strokeWidth = 1;
        bullet.circle.propertyFields.fill = "color";

        var bullet = lineSeries2.bullets.push(new am4charts.CircleBullet());
        bullet.circle.stroke = am4core.color("#7AEDE3");
        bullet.circle.strokeWidth = 1;
        bullet.circle.propertyFields.fill = "color";

        chart.cursor = new am4charts.XYCursor();
        // chart.legend = new am4charts.Legend();

        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarX.parent = chart.bottomAxesContainer;

        setTimeout(() => {
            $('g:has(> g[stroke="#3cabff"])').hide();
        }, 100);
    }
    //   const handleDaysValueChange = (event) => {
    //     setDaysValue(event.target.value);
    //   };



    const handleTimerChange = (id, selectedDaysValue, dateRangeValue) => {
        // You can now handle the change based on the id
        if (id === 'bar') {
            // console.log("new", dateRange)
            // Handle changes for the bar chart
        } else if (id === 'line') {
            // console.log("new2", dateRange)
            setDateRange({ ...dateRange, fromDate: dateRangeValue[0], toDate: dateRangeValue[1] })
            setSelectedDays(selectedDaysValue)
            // Handle changes for the line chart
        }
    };


    const getLineGraphData = () => {
        const payload = {
            SKU_ID: skuId,
            fromDate: dateRange?.fromDate || null,
            toDate: dateRange?.toDate || null
        };
        // if (fromDate && toDate) {
        //     payload.fromDate = fromDate;
        //     payload.toDate = toDate;
        // }
        dispatch(getSkuTrendingGraphData(payload, (data) => {
            setLoader(false);
            if (data?.success) {
                setLineGraphData(data?.data);
            }
        }));
    };

    useEffect(() => {
        setLoader(true)
        getLineGraphData()
    }, [skuId, dateRange])
    useEffect(() => {
        // barChart();
        lineChart();
    }, [lineGraphData]);
    return (loader ? (
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ p: 15 }} data- testid="loader">
            <Grid item>
                <GenericLoader />
            </Grid>
        </Grid>
    ) : (
        <>
            <Grid container lg={12} p={1} justifyContent="space-around" alignItems="center">
                <Grid item lg={6}>
                    <GenericGraphTimer
                        id="line"  // Unique identifier for this GenericGraphTimer
                        data-testid="graph-timer"
                        title="What is the sku fluctuation/trend over time ?"
                        options={timerOptions}
                        onChange={handleTimerChange}
                        selectedDays={selectedDays}
                    >
                    </GenericGraphTimer>
                </Grid>
                <Grid
                    container
                    item
                    lg={5}
                    // // sm={10}
                    justifyContent="end"
                    alignItems="end"
                    gap={1}
                    mb={1}
                // mr={4}

                >
                    <Item
                        sx={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: "#3E621E",
                            margin: "0 6px 0  0",
                        }}
                    ></Item>
                    <Typography
                        variant="contained"
                        sx={{ ...textClasses.t14n, color: "#000000" }}
                    >
                        Current Stock
                    </Typography>
                    <Item
                        sx={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: "blue",
                            margin: "0 6px 0  0",
                        }}
                    ></Item>
                    <Typography
                        variant="contained"
                        sx={{ ...textClasses.t14n, color: "#000000" }}
                    >
                        GateIn Count
                    </Typography>
                    <Item
                        sx={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: "red",
                            margin: "0 6px 0  0",
                        }}
                    ></Item>
                    <Typography
                        variant="contained"
                        sx={{ ...textClasses.t14n, color: "#000000" }}
                    >
                        GateOut Count
                    </Typography>
                </Grid>
            </Grid>
            <div style={{ width: "100%", height: "507px" }} id="line" data-testid="line-graph"></div>
        </>)
    )
}

export default SkuTrendingGraph;