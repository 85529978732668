import React, {  useState } from "react";
import styled from "@emotion/styled";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";

import theme, {
  buttonClasses,
  cardClasses,
  inputClasses,
  textClasses,
} from "../../../static/styles/theme";
import history from '../../../services/history'
import { ReactComponent as ReportBackgroundImg } from "../../../static/images/Icon/ReportBackgroundImg (2).svg";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import {  useSelector } from "react-redux";

export const reportCards = (permissionIds=[])=>{
  return [
    {
      title: "All Inward Transaction Report",
      header:'Inward Transaction Report',
      description:
        "You can map more than one report to a scheduler. This will be helpful when you want more than one report to be sent periodically.",
      icon:  <FileDownloadOutlinedIcon sx={{
        backgroundColor:'#3E621E',
        width:'100px',
        height:'50px'
      }}/>,
      urlpath: "",
      reportType: "inwardReport",
      permissionId: "inwardReport",
      templateType: ["inwardDataEntry","ASNTemplate"],
      iconSize: "",
      iconColor: "green",
    },
    {
      title: "Inventory Summary Report",
      header: "Inventory Summary Report",
      description:
        "You can map more than one report to a scheduler. This will be helpful when you want more than one report to be sent periodically.",
      icon: <InventoryOutlinedIcon  sx={{
        backgroundColor:'#FCCB3E',
        width:'100px',
        height:'50px'
      }} />,
      urlpath: "",
      reportType: "inventorySummaryReport",
      permissionId: "inventorySummaryReport",
      templateType: "itemMaster",
      iconSize: "",
      iconColor: "",
    },
    {
      title: "Item Master Report ",
      header: "Item Master Report ",
      description:
        "You can map more than one report to a scheduler. This will be helpful when you want more than one report to be sent periodically.",
      icon: <CategoryOutlinedIcon sx={{
        backgroundColor:'#1B2D89',
        width:'100px',
        height:'50px'
      }}  />,
      urlpath: "",
      reportType: "itemMasterReport",
      permissionId: "itemMasterReport",
      templateType: "itemMaster",
      iconSize: "",
      iconColor: "",
      skipTime:true
    },
    {
      
      title: "Inward Summary  Report ",
      header:'Inward Summary  Report',
      description:
        "You can map more than one report to a scheduler. This will be helpful when you want more than one report to be sent periodically.",
      icon: <FileDownloadOutlinedIcon sx={{
        backgroundColor:'#3E621E',
        width:'100px',
        height:'50px'
      }}/>,
      urlpath: "",
      reportType: "inwardSummaryReport",
      permissionId: "inwardSummaryReport",
      templateType:"",
      iconSize: "",
      iconColor: "",
    },
    {
      title: "Outward Summary Report ",
      header:'Outward Summary  Report',
      description:
        "You can map more than one report to a scheduler. This will be helpful when you want more than one report to be sent periodically.",
      icon: <FileUploadOutlinedIcon sx={{
        backgroundColor:'#E9001A',
        width:'100px',
        height:'50px'
      }} />,
      urlpath: "",
      reportType: "outwardSummaryReport",
      permissionId: "outwardSummaryReport",
      iconSize: "",
      iconColor: "",
    },
    
    {
      title: "Daily Stock Summary  Report ",
      header:'Daily Stock Summary  Report',
      description:
        "You can map more than one report to a scheduler. This will be helpful when you want more than one report to be sent periodically.",
      icon: <InventoryOutlinedIcon sx={{
        backgroundColor:'#FCCB3E',
        width:'100px',
        height:'50px'
      }}  />,
      urlpath: "",
      reportType: "stockTransferReport",
      permissionId: "stockTransferReport",
      iconSize: "",
      iconColor: "",
    },
    {
      title: "Stock Summary  Report ",
      header:'Stock Summary  Report',
      description:
        "You can map more than one report to a scheduler. This will be helpful when you want more than one report to be sent periodically.",
      icon: <InventoryOutlinedIcon sx={{
        backgroundColor:'#FCCB3E',
        width:'100px',
        height:'50px'
      }}  />,
      urlpath: "",
      reportType: "areaWiseStockSummaryReport",
      permissionId: "areaWiseStockSummaryReport",
      iconSize: "",
      iconColor: "",
    },
    {
      title: "Day Wise IR Stock Report",
      header:'Day Wise IR Stock Report',
      description:
        "You can map more than one report to a scheduler. This will be helpful when you want more than one report to be sent periodically.",
      icon: <InventoryOutlinedIcon sx={{
        backgroundColor:'#FCCB3E',
        width:'100px',
        height:'50px'
      }}  />,
      urlpath: "",
      reportType: "dayWiseIrStockReport",
      permissionId: "dayWiseIrStockReport",
      iconSize: "",
      iconColor: "",
    },
    {
      title: "IR Summary Report",
      header:'Day Wise IR Stock Report',
      description:
        "You can map more than one report to a scheduler. This will be helpful when you want more than one report to be sent periodically.",
      icon: <InventoryOutlinedIcon sx={{
        backgroundColor:'#FCCB3E',
        width:'100px',
        height:'50px'
      }}  />,
      urlpath: "",
      reportType: "IrStockSummaryReport",
      permissionId: "IrStockSummaryReport",
      iconSize: "",
      iconColor: "",
    },
    {
      title: "IR Closing Stock Report",
      header:'Day Wise IR Stock Report',
      description:
        "You can map more than one report to a scheduler. This will be helpful when you want more than one report to be sent periodically.",
      icon: <InventoryOutlinedIcon sx={{
        backgroundColor:'#FCCB3E',
        width:'100px',
        height:'50px'
      }}  />,
      urlpath: "",
      reportType: "IrClosingStockReport",
      permissionId: "IrClosingStockReport",
      iconSize: "",
      iconColor: "",
    },
    {
      title: "Product Type Wise Stock Report",
      header:'Product Type Wise Stock Report',
      description:
        "You can map more than one report to a scheduler. This will be helpful when you want more than one report to be sent periodically.",
      icon: <CategoryOutlinedIcon sx={{
        backgroundColor:'#1B2D89',
        width:'100px',
        height:'50px'
      }}  />,
      urlpath: "",
      reportType: "areaMappingWiseSKUSummaryReport",
      permissionId: "areaWiseSKUSummaryReport",
      iconSize: "",
      iconColor: "",
      skipTime:true
    },
    {
      title: "Cell Wise Area Utilization Report",
      header:'Cell Wise Area Utilization Report',
      description:
        "You can map more than one report to a scheduler. This will be helpful when you want more than one report to be sent periodically.",
      icon:     <Grid3x3Icon sx={{
        backgroundColor:'#E9001A',
        width:'100px',
        height:'50px'
      }}  />,
      urlpath: "",
      reportType: "cellwiseAreaUtilizationReport",
      permissionId: "cellwiseAreaUtilizationReport",
      iconSize: "",
      iconColor: "",
      skipTime:true
    },
    {
      title: "ASN Transaction Report",
      header:'ASN Transaction Report',
      description:
        "You can map more than one report to a scheduler. This will be helpful when you want more than one report to be sent periodically.",
      icon:     <FileDownloadOutlinedIcon sx={{
        backgroundColor:'#3E621E',
        width:'100px',
        height:'50px'
      }}/>,
      urlpath: "",
      reportType: "ASNReceivingReport",
      permissionId: "ASNReceivingReport",
      iconSize: "",
      iconColor: "",
    },
    {
      title: "Inventory Age Report",
      header:'Inventory Age Report',
      description:
        "You can map more than one report to a scheduler. This will be helpful when you want more than one report to be sent periodically.",
      icon:   <FileUploadOutlinedIcon sx={{
        backgroundColor:'#E9001A',
        width:'100px',
        height:'50px'
      }} />,
      urlpath: "",
      reportType: "inventoryAgeReport",
      permissionId: "inventoryAgeReport",
      iconSize: "",
      iconColor: "",
      skipTime:true
    },
    {
      title: "SKU History Report",
      header:'SKU History Report',
      description:
        "You can map more than one report to a scheduler. This will be helpful when you want more than one report to be sent periodically.",
      icon:   <InventoryOutlinedIcon sx={{
        backgroundColor:'#E9001A',
        width:'100px',
        height:'50px'
      }} />,
      urlpath: "",
      templateType: "itemMaster",
      reportType: "skuHistoryReport",
      permissionId: "skuHistoryReport",
      iconSize: "",
      iconColor: "",
      skipTime:false
    },
  ]?.filter((dl)=>permissionIds?.includes(dl?.permissionId));
}


const ReportDashboard = () => {
  const { userPermissions} = useSelector((state) => state.userPermission);
  const permissionIds = userPermissions?.permissions?.map(permission => permission.permissionId);
  const permittedReportCards = [...reportCards(permissionIds)];
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const handleReport = (item) => {
    history.push("/dashboard/report-Download/",{reportType:item?.reportType});
  };


  return (
    <>
      <Typography
        sx={{
          ...textClasses.cardTitle,
          color: "#000000",
        }}
        data-testid="reportDownload"
      >
        Download Reports
      </Typography>

      <Box
        sx={{
          backgroundColor: "#ff72002b",
          minHeight: "110px",
          width: "100%",
          marginTop: "15px",
          borderRadius: "8px",
          border: "1px solid #FF7200",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{padding:'10px'}}
        >
          <Grid
            item
            xs={12} sm={4} md={3} lg={2}
          >
            <ReportBackgroundImg  fullWidth/>
          </Grid>
          <Grid item xs={12} sm={8} md={9} lg={10}>
            <Typography
              sx={{
                ...textClasses.cardTitle,
                color: "#FF7200",
                padding: "8px 0 0 0 ",
                fontWeight: "700",
              }}
              data-testid="Schedule-Email"
            >
              Schedule Reports On Your Email(s)
            </Typography>
            <Typography
              sx={{
                ...textClasses.t16n,
                color: "#FF7200",
                fontWeight: "600",
                fontFamily: "Nunito",
              }}
            >
              You can map more than one report to a scheduler. This will be
              helpful when you want more than one report to be sent
              periodically.
            </Typography>
          </Grid>

          {/* <Grid
            item
            xs={3}
            sx={{
              textAlign: "end",
              margin: "10px 0 0 0",
            }}
          >
            <Box sx={{}}>
            <Button
              variant="contained"
              spacing={1}
              className="RF_sampledownload-btn"
              hidden={true}
              sx={{
                marginRight: "5px",
                borderRadius: "8px",
                backgroundColor: "black",
              }}
            >
              <ScheduleSendIcon sx={{
                marginRight:'10px'
              }} /> Schedule Email
            </Button>
            </Box>
          </Grid> */}
        </Grid>
      </Box>

      <Grid
        container
        spacing={3}
        sx={{
          marginTop: "10px",
        }}
      >
        {permittedReportCards.map((item, i) => (
          <>
            <Grid item xs={12} md={4} className="cp">
              <Paper elevation={3}>
                <Item>
                  <Card sx={{ boxShadow: "none" }} onClick={()=>handleReport((item))}>
                    <CardHeader
                      className="pt-2 pb-2"
                      avatar={
                        <Avatar
                          className="br-1"
                          sx={{ bgcolor: "white" }}
                          variant="square"
                        >
                          {item.icon}
                        </Avatar>
                      }
                      title={
                        <Typography
                          sx={{
                            textAlign: "left",
                            color: "#000000",
                            fontSize: "18px",
                            fontFamily: "Nunito",
                            fontWeight: "600",
                          }}
                          gutterBottom
                        >
                          {item.title}
                        </Typography>
                      }
                    />
                    <CardContent className="pt-0 pb-2">
                      <Typography
                        sx={{
                          textAlign: "left",
                          padding: "0 0 0 50px",
                          ...textClasses.t14n,
                          color: "#827878",
                          fontFamily: "Nunito",
                        }}
                        variant="body2"
                      >
                        {item.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Item>
              </Paper>
            </Grid>
          </>
        ))}
      </Grid>
    </>
  );
};
export default ReportDashboard;
