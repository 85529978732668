import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent, FormLabel, FormControlLabel, Switch,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import theme, {
  textClasses,
  cardClasses,
  inputClasses,
  buttonClasses,
} from "../../../static/styles/theme";
import { useDispatch, useSelector } from "react-redux";
import { getCamelCaseText, showToast,dateTimeFormater, showErrorDynamicField } from "../../../services/functions";
import {
  clearConnectedMasterDetail,
  fetchFile,
  generateSONumber,
  getAllTemplates,
  getSelectedTemplate,
  getShipmentConnectedMasterDetail,
  getTemplateHeader, getReceivingConfig
} from "../../../redux/actions";
import { selectStylesOverride } from "../../../utils/util";
import { useForm } from "react-hook-form";
import {
  getFieldsLayoutForEntry,
  getInputFieldForTableRow,
} from "../../Components/Utils";
import {
  FETCH_ORDER_CONNECTED_MASTER_DETAIL,
  FETCH_SHIPMENT_CONNECTED_MASTER_DETAIL,
} from "../../../redux/types";
import GenericLoader from "../../Components/Generic/GenericLoader";
import { handleDynamicChange, calculateTarget } from "../../../services/formFunction";
import { Add, Close, Delete, Edit, Remove, Search } from "@mui/icons-material";
import FileModal from "../EntityMaster/FileModal";
import SelectImage from "../../../../src/static/images/data_entry/select_location.svg";
import history from "../../../services/history";
import { saveSalesOrderEntry, saveSalesOrderWithExcel } from "../../../redux/actions/SalesOrder";
import CutomerSupplierAdd from "../TMS/Addressbook/cutomerSupplierAdd";
import GenericFileUpload from '../../Components/Generic/GenericFileUpload'
import GenericTable from '../../Components/CustomTable/GenericTable'
import { validateFieldsSingle, validateFieldsMultiple, convertExcelToArray, convertInExcel } from '../../../services/functions'
import Table2 from "../DataEntry/Asn/Table2";
import DetailDialog from "../DataEntry/Asn/DetailDialog";



const CreateSalesOrder = () => {
  const dispatch = useDispatch();

  const template = useSelector((state) => state.inward.selectedTemplate);
  const allTemplates = useSelector((state) => state.templates.allTemplates);
  const { userPermissions } = useSelector((state) => state.userPermission);

  const [items, setItems] = useState([]);
  const [apiCalled, setApiCalled] = useState(false);
  const [configDetail, setConfigDetail] = useState({});
  const [connectedCust_Sup_Detail, setConnectedCust_Sup_Detail] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [errors2, setErrors2] = useState([]);
  const [excelDataEntry, setExcelDataEntry] = useState({ action: false, file: "", viewTable: false });
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const totalQuantity = watch("quantity");
  const countTillNow = items.map((i) => Number(i.quantity)).reduce((a, b) => a + b, 0);
  const remainingItems = totalQuantity - countTillNow;

  const allFields = template?.otherTemplate?.length > 0 && template.otherTemplate[0].attributes?.length > 0 ? template.otherTemplate[0].attributes : [];

  const validateFields = () => {
    return validateFieldsMultiple(allFields || [], items || [])
  }

  const validateFieldsShipmentField = () => {

    let rowErrors = {};
    let row = getValues() || {}

    if (excelDataEntry?.action) {
      if (!excelDataEntry?.file || excelDataEntry?.file?.length == 0) {
        rowErrors["OrderDetail"] = {
          name: "Order Detail Excel",
          message: `Order Excel Detail is required`
        }
      }
    }
    else {
      if (!items || items.length == 0) {
        rowErrors["OrderDetail"] = {
          name: "Order Detail",
          message: `Order Detail is required`
        }
      }
    }
    // if (userPermissions?.permissions?.find(dl => dl.permissionId == "get_business_partners") &&
    //   configDetail?.customerRequired && (!connectedCust_Sup_Detail || !connectedCust_Sup_Detail.value)
    // ) {
    //   rowErrors["CustomerDetail"] = {
    //     name: "Customer Detail",
    //     message: `Customer Detail is required`
    //   }
    // }
    let tempErrors = validateFieldsSingle(template?.attributes || [], row || {}) || {}
    rowErrors = { ...rowErrors, ...tempErrors }
    return rowErrors;
  }


  const searchMaster = async (value, option = {}) => {
    let tempMaster = option?.value && option || {};
    setConnectedCust_Sup_Detail(tempMaster);
  };

  const checkItemSubmit = () => {
    let zeroCount = items.filter((it) => !it.quantity);
    if (zeroCount.length > 0) {
      showToast("Item count cannot be 0", true);
      return false;
    }

    let newErrors = validateFields();
    setErrors2(newErrors);

    if (newErrors.filter((row) => Object.keys(row).length != 0).length != 0) {
      showToast("Form Error: Please check for errors", true);
      return false;
    }
    else if (remainingItems != 0) {
      showToast(`Form Error: Total items should be equal to ${countTillNow + remainingItems}, currently = ${countTillNow}`, true);
      return false;
    }
    return true;
  };

  const handleDraft = (data) => {
    handleSave({ ...data, drafted: true });
  };

  const handleUpcoming = (data) => {
    handleSave({ ...data, drafted: false });
  };

  const handleSave = (data) => {
    const shipmentValidation = validateFieldsShipmentField();

    if (Object.keys(shipmentValidation).length > 0) {
      showToast(shipmentValidation[0].message, true);
      return
    }


    if (excelDataEntry?.action) {

      if (!excelDataEntry?.file || excelDataEntry?.file?.length == 0) {
        showToast(`Please Upload order detail excel file`, true)
        return;
      }
      const formData = new FormData();
      formData.append('file', excelDataEntry?.file[0]);
      formData.append('template_id', template._id);
      formData.append('drafted', data?.drafted || false);
      formData.append('uploadType', "save");
      formData.append('shipmentData', JSON.stringify({ ...data }));
      if (connectedCust_Sup_Detail?.value) {
        formData.append('supplierCustomerId', connectedCust_Sup_Detail?.value);
      }
      setApiCalled(true)
      dispatch(
        saveSalesOrderWithExcel(formData, (response) => {
          setApiCalled(false)
          if (response) {
            history.push("/dashboard/salesOrder");
          }
        })
      )
    }
    else {
      let payload = {
        template_id: template._id,
        shipmentData: data,
        drafted: data.drafted,
      };
      if (connectedCust_Sup_Detail?.value) {
        payload["supplierCustomerId"] = connectedCust_Sup_Detail?.value;
      }
      const itemValidation = checkItemSubmit();
      if (itemValidation) {
        if (template.connectedMaster && template.connectedMaster.itemMaster) {
          let nonSearched = items.filter((i) => i.itemDetail == null);
          if (nonSearched.length > 0) {
            showToast(
              `Please finish search of Product Id ${nonSearched[0].product_ID}`,
              true
            );
            return;
          }
        }
        payload["itemData"] = items;
        // //(payload);
        setApiCalled(true)
        dispatch(
          saveSalesOrderEntry(payload, (data) => {
            setTimeout(() => {
              setApiCalled(false)
            }, 500)
            if (data) {
              history.push("/dashboard/salesOrder");
            }
          })
        );
      }
    }
  };

  const handleClear = () => {

    let resetD = { ...getValues() };
    let rKeys = Object.keys(resetD);
    for (let i = 0; i < rKeys.length; i++) {
      resetD[rKeys[i]] = "";
    }
    reset({
      ...resetD,
    });
    setConnectedCust_Sup_Detail({});
    setItems([]);
    dispatch(clearConnectedMasterDetail());
    setExcelDataEntry({ ...excelDataEntry, file: "", viewTable: false });
  };

  useEffect(() => {
    dispatch(
      getAllTemplates(
        {
          type: "wms",
          templateType: "salesOrderTemplate",
          status: "published",
        },
        (data) => {
          if (data && data.length == 1) {
            setSelectedTemplate(data[0]);
          }
        }
      )
    );
    dispatch(getReceivingConfig({ configType: "salesOrderCreation" }, (data) => {
      setConfigDetail(data?.configDetail || {})
    }))
  }, []);

  useEffect(() => {
    // setPackages([]);
    handleClear();
    if (selectedTemplate != null) {
      dispatch(getSelectedTemplate({ template_id: selectedTemplate._id }));
      dispatch({ type: FETCH_SHIPMENT_CONNECTED_MASTER_DETAIL, payload: null });
      dispatch({ type: FETCH_ORDER_CONNECTED_MASTER_DETAIL, payload: null });
    }
  }, [selectedTemplate]);

  return (
    <>
      <Typography
        data-testid="Sales-Order"
        sx={{
          ...textClasses.cardTitle,
        }}
      >
        Sales Order Processing
      </Typography>
      <Breadcrumbs sx={{ mb: 1 }}>
        <Typography
          sx={{
            ...textClasses.normalText,
            fontSize: "12px",
            color: theme.themeOrange,
          }}
        >
          <Link
            style={{ color: theme.themeOrange, textDecoration: "none" }}
            to="/dashboard/salesOrder"
          >
            Sales Order Processing
          </Link>
        </Typography>

        <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }} data-testid="AddNewSOP">
          Add New SOP
        </Typography>
      </Breadcrumbs>
      <Paper
        elevation={1}
        sx={{ ...cardClasses.basic, borderRadius: "8px", p: 2, width: "100%" }}
      >
        <Grid container>
          <Grid container item sm={5} xs={5} alignContent="center">
            <Typography sx={{ ...textClasses.t20n, mt: "5px" }}>
              Template:{" "}
            </Typography>
            <Autocomplete
              value={selectedTemplate}
              onChange={(e, option) => {
                setSelectedTemplate(option);
                setExcelDataEntry({ action: false, file: "" })
                handleClear()
                setItems([])
              }}
              getOptionLabel={(option) => getCamelCaseText(option.name)}
              size="small"
              options={allTemplates}
              sx={{
                ...inputClasses.shadowField,
                minWidth: "220px",
                m: 0,
                ml: 2,
                ".MuiInputBase-input": {
                  fontSize: "14px",
                  color: "#455A64",
                },
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ color: "#455A64", ...textClasses.normalText }}
                  {...params}
                  label="Select Template"
                />
              )}
            />
          </Grid>
        </Grid>
        <Divider sx={{ m: "0 5px 0 5px", mb: 2, mt: 2 }} />

        {selectedTemplate != null && template ? (
          <>
            <ShipmentSection
            configDetail ={configDetail}
              template={template}
              connectedCust_Sup_Detail={connectedCust_Sup_Detail}
              searchMaster={searchMaster}
              formHooks={{
                register: register,
                control: control,
                setValue: setValue,
                errors: errors,
              }}
            />
            <OrderSection
              template={template}
              items={items}
              setItems={setItems}
              totalQuantity={totalQuantity}
              countTillNow={countTillNow}
              remainingItems={remainingItems}
              errors={errors2}
              setExcelDataEntry={setExcelDataEntry}
              excelDataEntry={excelDataEntry}
            />

            <Grid container direction={"row"} sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  ...buttonClasses.outlined,
                  color: theme.themeOrange,
                  minWidth: "150px",
                }}
                data-testid="clearbutton"
                onClick={handleClear}
                disabled={apiCalled}
              >
                Clear All
              </Button>
              {/* <Button
                variant="contained"
                size="small"
                sx={{
                  ...buttonClasses.small,
                  minHeight: "40px",
                  backgroundColor: theme.themeOrange,
                  minWidth: "150px",
                  ml: 2,
                }}
                onClick={handleSubmit(handleDraft)}
              >
                Draft
              </Button> */}
              <Button
                data-testid="submitbutton"
                variant="contained"
                size="small"
                sx={{
                  ...buttonClasses.small,
                  minHeight: "40px",
                  backgroundColor: theme.themeOrange,
                  minWidth: "150px",
                  ml: 2,
                }}
                disabled={apiCalled || validateFields()?.length > 0 || items?.length == 0}
                onClick={handleSubmit(handleUpcoming,showErrorDynamicField)}
              >
                Submit
              </Button>
            </Grid>
          </>
        ) : (
          <Grid
            container
            direction={"column"}
            justifyContent="center"
            alignItems="center"
            sx={{ p: 4 }}
          >
            <img src={SelectImage} />
            <Typography sx={{ ...textClasses.cardTitle, mt: 2 }}>
              select a template first..
            </Typography>
            <Typography
              sx={{
                ...textClasses.t12n,
                textAlign: "center",
                maxWidth: "500px",
                mt: 2,
              }}
            >
              Hey, you need to select a previously SOP template, to enter
              details for upcoming shipment.
            </Typography>
          </Grid>
        )}
      </Paper>
    </>
  );
};

export default CreateSalesOrder;

const ShipmentSection = ({
  template,
  connectedCust_Sup_Detail = {},
  searchMaster,
  formHooks,
  dimensions,
  configDetail={},
}) => {
  const dispatch = useDispatch();
  const { userPermissions } = useSelector((state) => state.userPermission);

  const { register, control, setValue, errors } = formHooks;
  const [loader, setLoader] = useState("");
  const [soGenerated, setSOGenerated] = useState(false);
  // //(" Template Header", templateHeader);
  const allFields =
    template?.attributes?.map((field, i) => {
      if (field._id == "purchaseOrderID" && soGenerated) {
        field.disabled = true;
      }
      return field;
    }) || [];
  const mandatoryFields = useSelector(
    (state) => state.canvas.components.attributes.mandatory
  ).filter((f) => f.templateType == template.templateType);
  const otherFields = allFields?.filter(
    (f) => mandatoryFields.map((m) => m._id).indexOf(f._id) < 0
  );



  const autoFillOnFocus = () => {
    const socketInputs = ["weight", "length", "breadth", "height"];
    if (dimensions) {
      for (let i = 0; i < otherFields.length; i++) {
        let compId = otherFields[i]._id;
        if (socketInputs.indexOf(compId) >= 0 && dimensions[compId]) {
          setValue(compId, dimensions[compId]);
        }
      }
    }
  };

  const layout = getFieldsLayoutForEntry(
    allFields,
    register,
    control,
    autoFillOnFocus,
    errors
  );

  const dispatchOrderNoGenerator = () => {
    setLoader("fetchSONumber");
    dispatch(
      generateSONumber(
        { templateId: template?._id || "" },
        ({ success = false, message, data = "" }) => {
          showToast(message, !success);
          setTimeout(() => {
            setLoader("");
          }, 500)
          if (success) {
            formHooks.setValue("salesDetailID", data);
            // setPOGenerated(true);
          }
        }
      )
    );
  };



  return (
    <Grid container>
      <Grid item sm={12} xs={10}>
        <Card sx={{ borderRadius: "8px" }}>
          <CardHeader
            sx={{
              padding: "5px 15px",
              borderRadius: "8px 8px 0px 0px",
              backgroundColor: theme.themeGray,
              color: "black",
              boxShadow: "0px 1px 10px gray",
            }}
            title={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ ...textClasses.normalText }}>
                  1. Shipment Details
                </Typography>
                {template?.attributes?.find(
                  (dl) => dl?._id == "salesDetailID"
                ) && (
                    <Button
                      style={{ ...buttonClasses.lynkitOrangeEmpty }}
                      onClick={() => dispatchOrderNoGenerator()}
                      disabled={loader == "SO_Number" || soGenerated}
                    >
                      Generate{" "}
                      {template?.attributes?.find(
                        (dl) => dl?._id == "salesDetailID"
                      )?.label || "SO Number"}
                    </Button>
                  )}
              </Box>
            }
          />
          <CardContent>
            <Grid container sx={{ mb: 1 }} spacing={2}>
              {layout.single.map((field, i) => {
                // if(field._id=="saleOrderId" && poGenerated){
                //     field.disabled = true
                // }
                return (
                  <Grid
                    container
                    item
                    xs={6}
                    sm={4}
                    justifyContent={"center"}
                    alignItems="center"
                    key={i}
                  >
                    {field}
                  </Grid>
                );
              })}
            </Grid>

            <Grid container spacing={2} sx={{ pt: 1 }}>
              {layout.multi.map((field, i) => {
                return (
                  <Grid
                    container
                    item
                    xs={6}
                    sm={4}
                    justifyContent={"center"}
                    alignItems="center"
                    key={i}
                  >
                    {field}
                  </Grid>
                );
              })}
            </Grid>

            {/* Customer detail section */}
            {userPermissions?.permissions?.find(dl => dl.permissionId == "get_business_partners")  && template?.connectedWithAddressbook?.at(0) &&  
            <CutomerSupplierAdd
              connectedCust_Sup_Detail={connectedCust_Sup_Detail}
              searchMaster={searchMaster}
              type={"Customer"}
              filter={configDetail?.filter || []}
              header={configDetail?.header || []}
              connectedWithAddressBook={template?.connectedWithAddressbook || {}}
              heading={configDetail?.heading || "Customer Detail"}
            />}

          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const OrderSection = ({
  template,
  errors,
  dimensions,
  items,
  setItems,
  totalQuantity,
  countTillNow,
  remainingItems, setExcelDataEntry, excelDataEntry,
  validationCallback,
}) => {
  const dispatch = useDispatch();
  const { reset } = useForm();
  const { userPermissions } = useSelector((state) => state.userPermission);
  const mandatoryFieldsId = useSelector(state => state.canvas.components.itemTemplate.mandatory).filter(
    (f) => f.templateType == template.templateType
  ).map(f => f._id);

  const [dialogData, setDialogData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [modelTableData, setModelTableData] = useState([])

  const allFields = template.otherTemplate && template?.otherTemplate?.length ? template.otherTemplate?.at(0)?.attributes : [];
  const otherFields = allFields.filter(f => mandatoryFieldsId.indexOf(f._id) < 0)

  const masterType = (() => {
    if (template) {
      if (template.connectedMaster && template.connectedMaster.itemMaster) {
        return "itemMaster";
      }
    }
    return null;
  })();


  const modelTableRows = (modelTableData || [])?.map((row, i) => {
    let items = [];
    Object.keys(row || {}).map((key, j) => (
      items.push(
        <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: theme.themeOrange }}>
          {row[key]}
        </Typography>
      )
    ));
    return items;
  });


  const viewExcel = async () => {
    try {
      const file = excelDataEntry.file ? excelDataEntry.file[0] : null;
      // console.log("file",file)
      if (!file) {
        setModelTableData([]);
        return;
      }

      let tempData = await convertExcelToArray(file)
      // console.log("tempData", tempData)
      setModelTableData(tempData)
    }
    catch (error) {
      setModelTableData([])
      console.error("An error occurred:", error);
    }
  }

  const isDisableUpload = useMemo(() => {
    return !excelDataEntry?.action || !excelDataEntry?.file
  })

  const handleDeleteFile = () => {
    setExcelDataEntry({ ...excelDataEntry, file: "" })
    setModelTableData([])
  }


  const handleFileChange = useCallback((file) => {
    setExcelDataEntry(prev => ({
      ...prev,
      file: file.target.files,
    }))
    setModelTableData([])
  }, [setExcelDataEntry, setModelTableData])


  const autoFillOnFocus = (index) => {
    if (dimensions) {
      let oldItems = [...items];
      for (let i = 0; i < otherFields.length; i++) {
        let compId = otherFields[i]._id;
        if (
          ["weight", "length", "breadth", "height"].indexOf(compId) >= 0 &&
          dimensions[compId]
        ) {
          oldItems[index][compId] = dimensions[compId];
        }
      }
      setItems(oldItems);
    }
  };

  const convertDocsBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        }, 400);
      });
    } else {
    }
  };

  const handleGenericChange = async (event, i, fieldId) => {
    const targetField = allFields.find((f) => f._id == fieldId);
    let oldItems = [...items];
    let oldObject = { ...oldItems[i] };
    if (targetField.type == "file") {
      const file = await convertDocsBase64(event.target.files[0]);
      oldObject[fieldId] = file;
    } else if (targetField.type == "checkbox") {
      let oldValues = oldObject[fieldId] ? oldObject[fieldId] : [];
      if (event.checked) {
        oldValues.push(event.value);
      } else {
        let pos = oldValues.indexOf(event.value);
        oldValues.splice(pos, 1);
      }
      oldObject[fieldId] = [...oldValues];
    } else {
      oldObject[fieldId] = event.target.value;
    }


    if (["select"].includes(targetField.type) && targetField.setTargetValue) {
      oldObject["itemDetail"] = null
      if (event.target.value) {
        changeLoader(i, true)
        await handleDynamicChange(oldObject, targetField, { value: event.target.value || "" }, "itemDetail")
        if (!oldObject["itemDetail"] || Object.keys(oldObject["itemDetail"]).length == 0) {
          oldObject["itemDetail"] = null
        }
        oldObject["loader"] = false
        for(let fields of allFields){
          let isValue=oldObject[fields?._id]
          if(fields?.type=="checkbox"){
              isValue=isValue?.length>0
          }
          else{
              isValue=!!isValue
          }
          if(!isValue ){
              let tempValue=""
              if (fields?.setDefaultValue == true && fields?.defaultValueFunction && !fields?.renderFunction) {
                  tempValue = eval(fields?.defaultValueFunction || "")
                  if (fields?.type == "date") {
                      tempValue = dateTimeFormater(tempValue,fields?.format || "YYYY-MM-DD")
                  }
              }
              
              if (fields?.renderFunction) {
                  tempValue = eval(fields.renderFunction + "('" + JSON.stringify(oldObject) + "')")
                  if (fields?.type == "date") {
                      tempValue = dateTimeFormater(tempValue,fields?.format || "YYYY-MM-DD")
                  }
              }
          }
      }
      }
      else {
        calculateTarget(oldObject, targetField, {})
      }
    }
    oldItems[i] = { ...oldObject };
    setItems([...oldItems]);
  };

  const getTableHeader = (headerType) => {
    let base = ["Quantity", "Item Details"];
    if (masterType == "itemMaster") {
      base.push("Detail");
    }
    let otherHeader = allFields
      .filter((f) => ["product_ID", "quantity"].indexOf(f._id) < 0)
      .map((of) => {
        let label = of.label;
        if (
          headerType == "excel" &&
          ["select", "radio", "checkbox"].indexOf(of.type) >= 0
        ) {
          label = `${label} (${of.values.map((o) => o.value).join(",")})`;
        }
        return label;
      });
    return [...base, ...otherHeader];
  };

  const getItemRow = (i) => {
    let item = items[i];

    const adornment = (
      <InputAdornment position="end">
        <IconButton
          onClick={() => handleSearchButton(i)}
          disabled={
            item.product_ID
              ? item.product_ID.length < 3
              : "" || item.itemDetail != null
          }
        >
          <Search />
        </IconButton>
      </InputAdornment>
    );

    let base = [
      <ButtonGroup
        size="small"
        sx={{
          minWidth: "24px",
          ".MuiButtonGroup-grouped": { minWidth: "25px" },
        }}
      >
        <Button
          key="one"
          size="small"
          sx={{ ...buttonClasses.smallOrange, fontSize: "12px" }}
          onClick={() => handleCountChange(-1, i)}
        >
          <Remove fontSize="inherit" />
        </Button>
        <input
          type="number"
          style={{
            border: "1px solid #D9D9D9",
            maxWidth: "60px",
            fontSize: "12px",
            textAlign: "center",
          }}
          value={item.quantity || null}
          onChange={(e) => handleCountChange(e, i)}
          onWheel={(event) => event.target.blur()}
          onKeyDown={(e) => {
            if (["e", "E", "+", "-"].includes(e.key)) {
              e.preventDefault();
            } else if (
              e.key == "0" &&
              (e.target.value == "" || Number(e.target.value) == 0)
            ) {
              e.preventDefault();
            }
          }}
        />
        <Button
          key="three"
          size="small"
          sx={{ ...buttonClasses.smallOrange, fontSize: "12px" }}
          onClick={() => handleCountChange(1, i)}
        >
          <Add fontSize="inherit" />
        </Button>
      </ButtonGroup>,
      getInputFieldForTableRow(
        allFields.filter((f) => f._id == "product_ID")?.at(0) || {},
        items,
        i,
        handleGenericChange,
        autoFillOnFocus,
        errors,
        false,
        masterType == "itemMaster" ? adornment : null,
        (item && !!item.itemDetail)
      ),
    ];
    if (masterType == "itemMaster") {
      base.push(
        item.loader ? (
          <GenericLoader />
        ) : (
          <Typography
            sx={{
              ...textClasses.boldText,
              color: theme.themeOrange,
              cursor: "pointer",
            }}
            onClick={() => handleOpenDetail(i)}
          >
            {item.itemDetail != null ? "View Details" : "No data yet"}
          </Typography>
        )
      );
    }
    const others = allFields
      .filter((f) => ["product_ID", "quantity"].indexOf(f._id) < 0)
      .map((of) => {
        let comp = { ...of };
        // debugger;
        if (
          comp._id == "UOM" &&
          // masterType != null &&
          items[i][comp._id] != null &&
          items[i][comp._id] != ""
        ) {
          comp.disabled = true;
        } else {
          comp.disabled = false;
        }
        return getInputFieldForTableRow(
          comp,
          items,
          i,
          handleGenericChange,
          autoFillOnFocus,
          errors,
          false
        );
      });

    return [...base, ...others];
  };

  const header = getTableHeader();
  const rows = items.map((item, i) => {
    return getItemRow(i);
  });


  const handleAddRow = () => {
    // let row = {
    //     itemId: "",
    //     itemDetail: null,
    //     quantity: 1,
    //     loader: false
    // }
    // setItems([...items, row])

    let fieldsData = {};
    let fields = allFields.filter((f) => f._id != "quantity");
    for (let i = 0; i < fields.length; i++) {
      if (fields[i]?.type == "checkbox") {
        fieldsData[fields[i]._id] = [];
      } else if (fields[i]?.type == "file") {
        fieldsData[fields[i]._id] = null;
      } else {
        fieldsData[fields[i]._id] = "";
      }
    }
    let newRow = {
      quantity: remainingItems,
      itemDetail: null,
      loader: false,
      ...fieldsData,
    };
    setItems([...items, newRow]);
    reset();
  };

  const handleRemoveRow = (i) => {
    let oldItems = [...items];
    oldItems.splice(i, 1);
    setItems(oldItems);
  };

  const handleEditRow = (i) => {
    let oldItems = [...items];
    oldItems[i] = {
      ...oldItems[i],
      itemDetail: null,
    };
    setItems(oldItems);
  };

  const handleCountChange = (direction, i) => {
    let oldItems = [...items];
    if (direction)
      if (direction == -1 && Number(oldItems[i].quantity) > 1) {
        oldItems[i].quantity = Number(oldItems[i].quantity) - 1;
        setItems(oldItems);
      } else if (direction == -1 && oldItems[i].quantity == 1) {
        handleRemoveRow(i);
      } else if (direction == 1) {
        if (remainingItems > 0) {
          oldItems[i].quantity = Number(oldItems[i].quantity) + 1;
          setItems(oldItems);
        }
      } else {
        let maxCap =
          totalQuantity - (countTillNow - Number(oldItems[i].quantity));

        oldItems[i].quantity =
          Number(direction.target.value) > maxCap
            ? maxCap
            : Number(direction.target.value);
        setItems(oldItems);
      }
  };

  const changeLoader = (i, status) => {
    let oldItems = [...items];
    oldItems[i] = {
      ...oldItems[i],
      loader: status,
    };
    setItems(oldItems);
  };

  const handleSearchButton = (i) => {
    let id = items[i].product_ID;
    let payload = {
      template_id: masterType ? template.connectedMaster.itemMaster : "",
    };
    if (masterType) {
      payload["SKU_ID"] = id;
    }

    changeLoader(i, true);
    dispatch(
      getShipmentConnectedMasterDetail(masterType, payload, (data) => {
        if (data) {
          let oldItems = [...items];
          oldItems[i] = {
            ...oldItems[i],
            itemDetail: data,
            loader: false,
            UOM: data.formData.UOM,
          };
          setItems(oldItems);
        } else {
          let oldItems = [...items];
          oldItems[i] = {
            ...oldItems[i],
            itemDetail: null,
            loader: false,
          };
          setItems(oldItems);
        }
      })
    );
  };

  const handleOpenDetail = (i) => {
    setDialogData(items[i]?.itemDetail || {});
  };

  const handleCloseDetail = () => {
    setDialogData(null);
  };


  return (
    <Grid container sx={{ mt: 2 }}>
      <Grid item sm={12} xs={10}>
        <Card sx={{ borderRadius: "8px" }}>
          <CardHeader
            sx={{
              padding: "5px 15px",
              borderRadius: "8px 8px 0px 0px",
              backgroundColor: theme.themeGray,
              color: "black",
              boxShadow: "0px 1px 10px gray",
            }}
            title={
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 2 }}>
                <Typography sx={{ ...textClasses.normalText }}>2. Order Details</Typography>
                {userPermissions?.permissions?.find(dl => dl.permissionId == "bulkSalesOrderUpload") && template?._id
                  ?
                  <Grid container item sm={6} xs={6} alignContent="center" justifyContent={"end"}>
                    <FormControlLabel
                      control={
                        <>
                          <Switch
                            color="warning"
                            checked={excelDataEntry?.action == true}
                            onChange={(event) => {
                              setExcelDataEntry({ action: event.target.checked, file: "" })
                              setItems([])
                              setModelTableData([])
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          <FormLabel sx={{ color: theme.themeOrange }}>Upload Order Detail Excel</FormLabel>
                        </>
                      }
                    />
                  </Grid>
                  : ""
                }

              </Box>
            }
          />
          <CardContent>
            {
              excelDataEntry?.action
                ?
                <>
                  <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mb: -1 }}>
                    <GenericFileUpload
                      payload={excelDataEntry}
                      previewAvailable={true}
                      onChange={handleFileChange}
                      onUpload={false}
                      onDelete={handleDeleteFile}
                      handleDownload={() => convertInExcel(allFields || [], 'Sales_Sample_File')}
                      isDisableUpload={isDisableUpload}
                      viewExcel={viewExcel}
                    />
                  </Grid>
                  {modelTableRows?.length > 0 &&
                    <Box sx={{ mt: 3 }}>
                      <GenericTable
                        header={template?.otherTemplate?.at(0)?.attributes?.map(dl => dl.label) || []}
                        rows={modelTableRows?.length > 0 && modelTableRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || []}
                        pageCount={modelTableRows.length}
                        pageNumber={page}
                        handleChangePage={(event, newPage) => {
                          setPage(newPage);
                        }}
                        handleChangeRowsPerPage={(event) => {
                          setRowsPerPage(+event.target.value);
                          setPage(0);
                        }}
                        rowsPerPage={rowsPerPage}
                      />
                    </Box>
                  }
                </>
                :
                <>
                  <Grid container>
                    {/* <Table
                                            items={items}
                                            setItems={setItems}
                                            remainingItems={remainingItems}
                                            handleAddRow={handleAddRow}
                                            handleRemoveRow={handleRemoveRow}
                                            handleCountChange={handleCountChange}
                                            handleSearchButton={handleSearchButton}
                                            handleEditRow={handleEditRow}
                                            handleOpenDetail={handleOpenDetail}
                                        />   */}
                    <Table2
                      header={header}
                      rows={rows}
                      remainingItems={remainingItems}
                      handleAddRow={handleAddRow}
                      handleRemoveRow={handleRemoveRow}
                      handleEditRow={handleEditRow}

                    />
                  </Grid>
                  <Typography sx={{ ...textClasses.boldText, color: theme.themeOrange, mt: 2, ml: 2 }}>
                    Total Order Quantity: {countTillNow} pcs
                  </Typography>
                  {dialogData && <DetailDialog
                    data={dialogData}
                    onClose={handleCloseDetail}
                  />}
                </>
            }
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
