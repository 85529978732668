import {
    FETCH_ERROR, FETCH_SUCCESS, FETCH_START, FETCH_ITEM_DETAILS, FETCH_STOCK_UNIT_DATA,
    FETCH_ASSOCIATED_JOB_DETAILS, FETCH_JOB_QTY_DATA, FETCH_TRENDING_GRAPH_DATA
} from "../types"
import axios from "../../services/Api";

export const getSkuItemDetails = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inventory/inventoryJourneyDashboard', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_ITEM_DETAILS, payload: { data: data?.data || {}, templateHeader: data?.tableHeader || [] } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_ITEM_DETAILS, payload: { data: {}, templateHeader: [] } })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const getSkuAssociatedJobDetails = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inventory/getSKUJobJourney', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_ASSOCIATED_JOB_DETAILS, payload: { data: data?.data || {}, count: data?.count } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_ASSOCIATED_JOB_DETAILS, payload: { data: {}, count: 0 } })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};


export const setStockUnitData = (data) => {
    return {
        type: FETCH_STOCK_UNIT_DATA,
        payload: data || {},
    };
};

export const setJobQtyData = (data) => {
    return {
        type: FETCH_JOB_QTY_DATA,
        payload: data || [],
    };
};

export const getSkuTrendingGraphData = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inventory/getDayWiseSKUStocks', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_TRENDING_GRAPH_DATA, payload: { data: data?.data || []} })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_TRENDING_GRAPH_DATA, payload: { data: []} })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};
