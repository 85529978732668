import {
    FETCH_SHIPMENT_CONNECTED_MASTER_DETAIL, FETCH_ORDER_CONNECTED_MASTER_DETAIL,
    FETCH_SHIPMENT_CONNECTED_MASTER_ENTRIES, FETCH_ORDER_CONNECTED_MASTER_ENTRIES, FETCH_ERROR,
    FETCH_SUCCESS, FETCH_START, CLEAR_CONNECTED_MASTER_DETAIL, FETCH_ADVANCE_NOTICE_SHIPMENT,
    FETCH_ASN_STATUS_COUNT, FETCH_ASN_DETAIL, FETCH_SHIPMENT_TEMPLATE_HEADER, GENERATE_QR, GENERATE_PO_NUMBER
} from "../types"
import axios from "../../services/Api";
import { showToast } from "../../services/functions";

export const clearConnectedMasterDetail = () => {
    return {
        type: CLEAR_CONNECTED_MASTER_DETAIL
    }
}

export const getShipmentConnectedMasterDetail = (masterType, payload, cb) => {
    let url;
    if(masterType == "itemMaster"){
        url = "/inventory/getItemMaterDetail"
    }

    let actionMapping = {
        itemMaster: FETCH_ORDER_CONNECTED_MASTER_DETAIL
    }
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post(url, {
               ...payload,
        }).then(({data}) => {
            if (data.success) {
                callback(data);
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: actionMapping[masterType], payload: null})
                showToast(data.message, true)
                if(cb){
                    cb()
                }
            }
            function callback(data) {
                //("API data", data.data);
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: actionMapping[masterType], payload: data.data})
                if(cb){
                    cb(data.data)
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            if(cb){
                cb()
            }
        });
    }
};

export const getConnectedMasterEntries = (masterType, payload, cb) => {
    let url;
    if(masterType == "itemMaster"){
        url = "/inventory/getItemMasterData"
    }

    let actionMapping = {
        itemMaster: FETCH_ORDER_CONNECTED_MASTER_ENTRIES
    }
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post(url, {
               ...payload,
        }).then(({data}) => {
            if (data.success) {
                callback(data);
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: actionMapping[masterType], payload: []})
                // showToast(data.message, true)
                if(cb){
                    cb()
                }
            }
            function callback(data) {
                //("API data", data.data);
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: actionMapping[masterType], payload: data.data})
                if(cb){
                    cb(data.data)
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            if(cb){
                cb()
            }
        });
    }
};


export const saveAsnEntry = (payload, callback) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/ASN/saveASNData', payload).then(({data}) => {
            if (data.success) {
                process(data)
            } 
            else {
                //(data.message);
                dispatch({type: FETCH_ERROR, payload: data.message});
                showToast(data.message, true)
                callback(null);
            }
            function process(data) {
                dispatch({ type: FETCH_SUCCESS });
                showToast(data.message, false);
                callback(data);
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            callback(null)
        });
    }
};
export const saveAsnEntryWithExcel = (payload, callback) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/ASN/bulkASNUpload', payload).then(({data}) => {
            if (data.success) {
                process(data)
            } 
            else {
                //(data.message);
                dispatch({type: FETCH_ERROR, payload: data.message});
                showToast(data.message, true)
                callback(null);
            }
            function process(data) {
                dispatch({ type: FETCH_SUCCESS });
                showToast(data.message, false);
                callback(data);
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            showToast(error.message, true);
            callback(null)
        });
    }
};

export const editAsnEntry = (payload, callback) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/ASN/editASNData', payload).then(({data}) => {
            if (data.success) {
                process(data)
            } 
            else {
                //(data.message);
                dispatch({type: FETCH_ERROR, payload: data.message});
                showToast(data.message, true)
                callback(null);
            }
            function process(data) {
                dispatch({ type: FETCH_SUCCESS });
                showToast(data.message, false);
                callback(data);
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            callback(null)
        });
    }
};


export const getAllASN = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/ASN/getASNList', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_ADVANCE_NOTICE_SHIPMENT, payload: {rows: [], count: 0}})
                if(cb){
                    cb();
                }
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_ADVANCE_NOTICE_SHIPMENT, payload: {rows: data.data, count: data.count}})
                if(cb){
                    cb(data.data);
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //('here')
            //("Error****:", error.message);
            if(cb){
                cb();
            }
        });
    }
};


export const getASNStatusCount = (payload) =>{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('ASN/getASNStatusWiseCount', {
            ...payload
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback(data);
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: FETCH_ASN_STATUS_COUNT, payload: null})
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: FETCH_ASN_STATUS_COUNT, payload: data.data})
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};
export const generatePONumber = (payload,callback) =>{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('base/generatePONumber', {
            ...payload
        }
        ).then(({data}) => {
        if (data.success){
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: GENERATE_PO_NUMBER, payload: data.data})
        } 
        else {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: GENERATE_PO_NUMBER, payload: ""})
        }
        if(callback){
            callback(data)
        }

        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch({ type: GENERATE_PO_NUMBER, payload: ""})
            if(callback){
                callback({success:false,message:error.message})
            }
            //("Error****:", error.message);
        });
    }
};

export const getAsnDetails = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('ASN/getASNDetail', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success) 
            {
                callback(data);
            } 
            else 
            {
                dispatch({type: FETCH_ERROR, payload: data.message});
                if(payload.filterTable){
                    // dispatch({ type: FETCH_ASN_DETAIL, payload: null})
                    // Dont do anything
                    // console.log("ASN DETAIL YES");
                }
                else{
                    dispatch({ type: FETCH_ASN_DETAIL, payload: {data: null, count: 0}})
                }
                showToast(data.message, true);
                if(cb){
                    cb()
                }
            }

            function callback(data) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_ASN_DETAIL, payload: {data: data.data, count: data.count}})
                if(cb){
                    cb(data.data)
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            showToast("Something went wrong", true);
            if(cb){
                cb()
            }
        });
    }
};
export const getReceivingConfig = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('base/getCommanConfig', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
            }
            
            if(cb){
                cb(data?.data || {})
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            if(cb){
                cb()
            }
        });
    }
};

export const rejectAsn = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('ASN/rejectASN', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success) 
            {
                callback(data);
            } 
            else 
            {
                dispatch({type: FETCH_ERROR, payload: data.message});
                showToast(data.message, true);
                if(cb){
                    cb()
                }
            }

            function callback(data) {
                dispatch({ type: FETCH_SUCCESS });
                if(cb){
                    cb(data.data)
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            showToast(error.message, true);
            if(cb){
                cb()
            }
        });
    }
};

export const closeAsn = (payload, cb) =>{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('ASN/closeASN', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success){
                dispatch({ type: FETCH_SUCCESS });
                showToast(data.message, false);
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                showToast(data.message, true);
            }
            if(cb){
                cb(data)
            }
            
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            showToast(error.message, true);
            if(cb){
                cb({success:false,message:error.message})
            }
        });
    }
};

export const approveASNArrival = (payload, cb) =>{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('ASN/approveASNArrival', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success){
                dispatch({ type: FETCH_SUCCESS });
                showToast(data.message, false);
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                showToast(data.message, true);
            }

            if(cb){
                cb(data)
            }
            
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            showToast(error.message, true);
            if(cb){
                cb({success:false,message:error.message})
            }
        });
    }
};


export const getShipmentMasterHeader = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('inventory/getTemplateHeader', {
            ...payload
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback(data);
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: FETCH_SHIPMENT_TEMPLATE_HEADER, payload: []})
            if(cb){
                cb();
            }
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: FETCH_SHIPMENT_TEMPLATE_HEADER, payload: data.templateHeader})
            if(cb){
                cb(data.templateHeader)
            }
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};