
import {
    FETCH_SHIPMENT_CONNECTED_MASTER_DETAIL, FETCH_ORDER_CONNECTED_MASTER_DETAIL,
    FETCH_SHIPMENT_CONNECTED_MASTER_ENTRIES, FETCH_ORDER_CONNECTED_MASTER_ENTRIES, FETCH_ERROR,
    FETCH_SUCCESS, FETCH_START,FETCH_KIT,FETCH_KIT_STATUS_COUNT,FETCH_KIT_DETAIL,CLEAR_CONNECTED_MASTER_DETAIL, FETCH_ADVANCE_NOTICE_SHIPMENT,
    FETCH_ASN_STATUS_COUNT, FETCH_ASN_DETAIL, FETCH_SHIPMENT_TEMPLATE_HEADER, GENERATE_QR, GENERATE_PO_NUMBER,FETCH_CURRENT_KITTING_COUNT
} from "../types"
import axios from "../../services/Api";
import { showToast } from "../../services/functions";
export const getAllKits = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/KIT/getAllKits', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_KIT, payload: {rows: [], count: 0}})
                if(cb){
                    cb();
                }
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_KIT, payload: {rows: data.data, count: data.count}})
                if(cb){
                    cb(data.data);
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //('here')
            //("Error****:", error.message);
            if(cb){
                cb();
            }
        });
    }
};

export const saveKitEntry = (payload, callback) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/kit/saveKitData', payload).then(({data}) => {
            if (data.success) {
                process(data)
            } 
            else {
                //(data.message);
                dispatch({type: FETCH_ERROR, payload: data.message});
                showToast(data.message, true)
                callback(data.success);
            }
            function process(data) {
                dispatch({ type: FETCH_SUCCESS });
                showToast(data.message, false);
                callback(data);
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            callback(null)
        });
    }
};

export const getKITStatusCount = (payload) =>{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('kit/getKitStatusWiseCount', {
            ...payload
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback(data);
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: FETCH_KIT_STATUS_COUNT, payload: null})
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: FETCH_KIT_STATUS_COUNT, payload: data.data})
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const getKitDetails = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('kit/getKitDetails', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success){
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_KIT_DETAIL, payload: {data: data?.data || {}, count: data?.count}})
            } 
            else{
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_KIT_DETAIL, payload: {data: {}, count: 0}})
            }

            if(cb){
                cb(data)
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            if(cb){
                cb({success:false, message: error.message})
            }
        });
    }
};


export const approveRejectCompleteSales = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('kit/updateKitStatus', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
            }

            if(cb){
                cb(data)
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
          
            showToast(error.message, true);
            if(cb){
                cb({success:false,message: error.message})
            }
        });
    }
};

export const startKitPartialBundlingProcess = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('kit/createKitPartialBundelingJob', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
            }
			if(cb){
				cb(data)
			}
    
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            if(cb){
                cb({success:false,message:error.message})
            }
        });
    }
};

export const getCurrentKitCount = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('kit/getBundelingReadyKitsCount', {
            ...payload
        }
        ).then(({ data }) => {
            console.log("actionData",data)
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_CURRENT_KITTING_COUNT, payload: data.count })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_CURRENT_KITTING_COUNT, payload: null })
            }
            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
  };
  