import { 
    Button, Grid, MenuItem, Paper, Select, Typography,Tooltip, Box, Tabs, Tab, FormControl, OutlinedInput, InputAdornment, Card, CardHeader, 
    IconButton, CardContent, TablePagination, Modal, TextField, Menu, Stack, Dialog, DialogContent, Checkbox ,Popover, Divider, Autocomplete,ListSubheader,ListItemText
} from '@mui/material'
import { Close, Search, SellOutlined, KeyboardArrowDown, QrCodeScanner } from '@mui/icons-material'
import React, { useEffect, useState, useCallback } from 'react'
import theme, { buttonClasses, cardClasses, inputClasses, textClasses } from '../../../static/styles/theme'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTemplates,getTemplateHeader,resetStoreState,
     getItemMaster, getItemMasterDetail,getMasterTemplateWiseCount, getAllUsers, fetchFile
 } from '../../../redux/actions'
import { getHeader } from './Utils'
import { DatePicker } from '@mui/x-date-pickers'
import AddViewEdit from "./ViewEdit"
import DeleteItem from "./DeleteItem"
import UploadItem from "./UploadItem"
import moment from 'moment'
import ImageIcon from '@mui/icons-material/Image';
import { Add } from "@mui/icons-material";
import PublishIcon from '@mui/icons-material/Publish';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import { useDebounce } from "../../../utils/hooks";
import EmptyPage from '../EntityMaster/EmptyPage'
import { selectStylesOverride } from "../../../utils/util";
import { modelInfoSingle,titleMap } from './Utils';
import history from '../../../services/history'
import GenericTable from "../../Components/CustomTable/GenericTable"
import {getShortText,exportToExcel,capitalizeSentence,dateTimeFormater,deepCopy_Object } from "../../../services/functions";
import FileModal from './FileModal';
import Label from "../../Components/Generic/GenericLabel"
import GenericLoader from '../../Components/Generic/GenericLoader'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ConnectedComponentsTable from './ConnectedSkuModal'
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import RFIDMappingModal from './RFIDMappingModal'
import ViewAttachedTagsModal from './ViewAttachedTagsModal'
import GenericRangeFilter from '../../Components/Generic/GenericRangeFilter'
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
let stockStatusFilterOptions = [
    { label: 'Select All', value: 'allStock' },
    { label: 'OK Stock', value: 'okStock' },
    { label: 'Low Stock', value: 'lowStock' },
    { label: 'High Stock', value: 'highStock' }
]
let finalObj = {
    "template_id": '',
    "page": 1,
    "limit": 10,
    "filters": {},
    "initial": true,
    "createdAt":null,
    "stockFilter":{minStock:"",maxStock:""},
    "stockStatusFilter":[]
}

const labelTypes = [
    // {
    //     label: "Shipment Only",
    //     value: "shipmentOnly",
    //     icon: ShipmentOnlyIcon,
    //     allowed: ["shipmentOnly", "shipmentPackageOnly", "shipmentItemOnly", "SPI"]
    // },
    // {
    //     label: "Package/Box Only",
    //     value: "packageOnly",
    //     icon: PackageOnlyIcon,
    //     allowed: ["shipmentPackageOnly", "SPI"]
    // },
    // {
    //     label: "Every Individual Items",
    //     value: "everyItem",
    //     icon: EveryItemIcon,
    //     allowed: ["shipmentItemOnly", "SPI"]
    // },
    // {
    //     label: "Package/Box & Single Item Details",
    //     value: "packageAndSingleItem",
    //     icon: PackageAndSingleItemIcon,
    //     allowed: ["SPI"]
    // },
    // {
    //     label: "Single Item Only",
    //     value: "singleItem",
    //     icon: SingleItemIcon,
    //     allowed: ["shipmentItemOnly", "SPI"]
    // }
]

export default function ItemMaster() {
    const dispatch = useDispatch();
    const allTemplates = useSelector(state => state.templates.allTemplates);
    const totalRows = useSelector(state => state.itemMaster.count);
    const tempateData = useSelector(state => state.itemMaster.ItemMaster);
    const ItemDetail = useSelector(state => state.itemMaster.ItemDetail);
    const { userPermissions } = useSelector((state) => state.userPermission);
    const mastertemplateWiseCount = useSelector(state => state.itemMaster.masterTemplateWiseCount || {});


    const [loader, setLoader] = useState(true)
    const [cardLoader, setCardLoader] = useState(true)
    const [selectedRow, setSelectedRow] = useState(null);
    const [tabIndex, setTabIndex] = useState(0);
    const [checkedArray, setCheckedArray] = useState([])
    const [ViewEditState, setViewEditState] = useState(false)
    const [deleteStatus, setDeleteStatus] = useState(false)
    const [actionStatus, setActionStatus] = useState("view");
    const [sharedObj, setSharedObj] = useState({})
    const [templateIndex, setTemplateIndex] = useState(-1)
    const [filter, setFilter] = useState({ ...finalObj });
    const [detailModal, setDetailModal] = useState(null);
    const [uploadStatus, setUploadStatus] = useState(false)
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [displayUrl, setDisplayUrl] = useState('')
    const [openModal, setOpenModal] = React.useState(false);
    const [labelModal, setLabelModal] = useState(null);
    //state and index for popover
    const [anchorStock, setAnchorStock] = useState({anchor:null,index:-1});
    // console.log(anchorStock,"anchorStock")
    const urlPath = window?.location?.pathname?.split('/')[3] || "dashboard";
    let apiKeyword = modelInfoSingle[urlPath]["apiPath"]
 
    const [anchorEl1, setAnchorEl1] = useState(null);
    const handleClick1 = (event) => {
      setAnchorEl1(event.currentTarget);
    };
  
    const handleClose1 = () => {
      setAnchorEl1(null);
    };
  
    const open1 = Boolean(anchorEl1);
    const id1 = open1 ? 'simple-popover' : undefined;
    let statusMapping = {
        'inUse': {
            color: "#3E621E",
            background: "#D7ECC4",
            label: "In-Use"
        },
        'damaged': {
            color: "#FF0101",
            background: "#E9001A26",
            label: "Damaged"
        },
        'available': {
            color: "#FF7200",
            background: "#ff720026",
            label: "Available"
        }
    }
    const handleSelectionChange = (result,isAllSelected=false) => {
      if(isAllSelected){
        let tempArr = stockStatusFilterOptions?.filter(dl=> dl?.value != "allStock");
        if(tempArr?.length == filter?.stockStatusFilter?.length){
            tempArr=[];
        }
        setFilter({ ...filter,initial: false, stockStatusFilter: [...tempArr] })
      }else{
        setFilter({ ...filter,initial: false, stockStatusFilter: [...result] })
      }
    };

    const checkPermission = (key = "") => {
        if (!key) return false
        if (urlPath == "item-master") {
            if (key == "view") {
                return userPermissions?.permissions?.find(dl => dl.permissionId == "viewItemMaster") || false
            }
            else if (key == "add") {
                return userPermissions?.permissions?.find(dl => dl.permissionId == "addItemMaster") || false
            }
            else if (key == "delete") {
                return userPermissions?.permissions?.find(dl => dl.permissionId == "deleteItemMaster") || false
            }
            else if (key == "edit") {
                return userPermissions?.permissions?.find(dl => dl.permissionId == "updateItemMaster") || false
            }
            else if (key == "scan") {
                return userPermissions?.permissions?.find(dl => dl.permissionId == "scanItemMaster") || false
            }
            else if(key=="inventoryJourney"){
                return userPermissions?.permissions?.find(dl => dl.permissionId == "viewInventoryJourneyItemMaster") || false
            }
            else{
                return false
            }

            //customer-master
        }
        // else if (urlPath == "customer-master") {
        //     if (key == "view") {
        //         return userPermissions?.permissions?.find(dl => dl.permissionId == "viewCustomerMaster") || false
        //     }
        //     else if (key == "add") {
        //         return userPermissions?.permissions?.find(dl => dl.permissionId == "addCustomerMaster") || false
        //     }
        //     else if (key == "delete") {
        //         return userPermissions?.permissions?.find(dl => dl.permissionId == "deleteCustomerMaster") || false
        //     }
        //     else if (key == "edit") {
        //         return userPermissions?.permissions?.find(dl => dl.permissionId == "updateCustomerMaster") || false
        //     }
        //     else {
        //         return false
        //     }

        // }
        else if(urlPath=="kit-master"){
            if(key=="view"){
                return userPermissions?.permissions?.find(dl => dl.permissionId == "viewKitMaster") || false
            }
            else if (key == "add") {
                return userPermissions?.permissions?.find(dl => dl.permissionId == "addKitMaster") || false
            }
            else if (key == "delete") {
                return userPermissions?.permissions?.find(dl => dl.permissionId == "deleteKitMaster") || false
            }
            else if (key == "edit") {
                return userPermissions?.permissions?.find(dl => dl.permissionId == "updateKitMaster") || false
            }
            else if (key == "copy") {
                return userPermissions?.permissions?.find(dl => dl.permissionId == "copyKitMaster") || false
            }
            else if(key=="scan"){
                return userPermissions?.permissions?.find(dl => dl.permissionId == "scanKitMaster") || false
            }
            else if(key=="inventoryJourney"){
                return userPermissions?.permissions?.find(dl => dl.permissionId == "viewInventoryJourneyKitMaster") || false
            }
            else {
                return false
            }

        }
        else if (urlPath == "pallet-master") {
            if (key == "view") {
                return userPermissions?.permissions?.find(dl => dl.permissionId == "viewPalletMaster") || false
            }
            else if (key == "add") {
                return userPermissions?.permissions?.find(dl => dl.permissionId == "addPalletMaster") || false
            }
            else if (key == "delete") {
                return userPermissions?.permissions?.find(dl => dl.permissionId == "deletePalletMaster") || false
            }
            else if (key == "edit") {
                return userPermissions?.permissions?.find(dl => dl.permissionId == "updatePalletMaster") || false
            }
            else if (key == "attachTag") {
                return userPermissions?.permissions?.find(dl => dl.permissionId == "attachPalletMaster") || false
            }
            else if(key=="scan"){
                return userPermissions?.permissions?.find(dl => dl.permissionId == "scanPalletMaster") || false
            }
            else {
                return false
            }

        }
        else {
            return false
        }

    }

    const displayFile = (fileUrl, fileKey) => {
        const imageUrlRegex = /\.(jpg|jpeg|png|gif|bmp|tiff)$/i;
        const isValidImageUrl = imageUrlRegex.test(fileKey);
        if (isValidImageUrl) {
            setOpenModal(true)
            setDisplayUrl(fileUrl);
        } else {
            window.open(fileUrl, '_blank');
        }
    }
    const handleClick = (fileKey) => {
        setDisplayUrl('');
        dispatch(fetchFile({ fileKey }, displayFile))
    }
    const allHandleChecked = (e) => {
        let apiResponse = [...tempateData];
        let result = [...checkedArray]
        if (e.target.checked === true) {
            // result.length = 0
            for (let i = 0; i < apiResponse.length; i++) {
                let id = apiResponse[i]._id
                result.push(id)
                setCheckedArray(result)

                let keyName = ['item-master',"kit-master","pallet-master"].includes(urlPath) ? 'SKU_ID' : '' || urlPath === 'customer-master' ? "customerID" : '' || urlPath === 'supplier-master' ? "supplierID" : ''
                let sT = apiResponse.map(data => ({ name: data?.formData[keyName] || '', id: data?._id }))
                setSelectedTemplates(sT);
            }
        }
        else {
            // result.length = 0
            setCheckedArray(result)
            setSelectedTemplates([]);
        }
    }
    const handleSelect = (e, index, data) => {

        let apiResponse = [...tempateData];
        let result = [...checkedArray]
        let id = apiResponse[index]._id
        if (!result.includes(id)) {
            result.push(id);
            setCheckedArray(result)
            setSharedObj(data)
            handleAddSelection(data);
        }
        else {
            result.splice(result.indexOf(id), 1);
            setCheckedArray(result)
            handleRemoveSelection(data);
        }
    }

    const handleAddSelection = (data) => {
        let keyName = ['item-master',"kit-master","pallet-master"].includes(urlPath) ? 'SKU_ID' : '' || urlPath === 'customer-master' ? "customerID" : '' || urlPath === 'supplier-master' ? "supplierID" : ''
        let obj = {
            name: data?.formData ? data?.formData[keyName] : "",
            id: data?._id
        }
        setSelectedTemplates([...selectedTemplates, obj]);
    }

    const handleRemoveSelection = (data) => {
        let templateId = data?._id || ''
        let oldTemplates = [...selectedTemplates];
        const i = oldTemplates.map(o => o.id).indexOf(templateId);
        if (i >= 0) {
            oldTemplates.splice(i, 1);
            setSelectedTemplates(oldTemplates);
        }

    }

    const handleAction = (data, index, action) => {
        if (action === 'add') {
            setViewEditState(true)
            setActionStatus(action);
        }
        else if (action === 'upload') {
            setUploadStatus(true)
            setActionStatus(action);
        }
        else if (action === 'view') {
            setViewEditState(true)
            setActionStatus(action);
            setSharedObj(data)
        }
        else if (action === 'edit') {
            setViewEditState(true)
            setActionStatus(action);
            setSharedObj(data)
        }
        else if (action === 'Delete') {
            setDeleteStatus(true)
            setActionStatus(action);
            setSharedObj(data)
        }
        else if (action === 'copy') {
            setViewEditState(true)
            setActionStatus(action);
            setSharedObj(data)
        }
        else if(action === 'attachTag'){
            setActionStatus(action)
            setSharedObj(data)
        }
        else if(action === 'viewAttachedTags'){
            setActionStatus(action)
            setSharedObj(data)
        }
        else if (action === 'Deletes') {
            setDeleteStatus(true)
            setActionStatus('Delete');
            setSharedObj(data)


            let keyName = ['item-master',"kit-master","pallet-master"].includes(urlPath) ? 'SKU_ID' : '' || urlPath === 'customer-master' ? "customerID" : '' || urlPath === 'supplier-master' ? "supplierID" : ''
            let obj = {
                name: data?.formData ? data?.formData[keyName] : "",
                id: data?._id
            }
            setSelectedTemplates([obj]);
        }
    }

    const handleCloseDetailModal = () => {
        setDetailModal(null);
    }


    const handleSelectedRow = (option) => {
        setSelectedRow(option);
    }

      
    // let temp={...column_data}
    // 			temp.props={
    // 				...column_data,
    // 				children:getShortText(column_data.props.children, 20)
    // 			}
    // 			return <Tooltip title={column_data} placement="top">{temp}</Tooltip>

    const renderData = (column_data) => {
        if (["number", "string"].includes(typeof column_data)) {
            if (column_data.length < 20) return column_data
            else {
                return <Tooltip title={column_data} placement="top">
                    <Typography sx={{ ...textClasses.t13n, textTransform: "capitalize" }}>
                        {getShortText(column_data, 20)}
                    </Typography>
                </Tooltip>
            }
        }
        else {
            return column_data
        }

    }

    const handleOpenLabelModal = (selectedIds, template) => {
        const selectedObjs = tempateData.filter(d => selectedIds.indexOf(d._id) >= 0)?.map(dl=>({...dl?.formData || {}}));
        setLabelModal({
            data: selectedObjs,
            templateHeader: template?.attributes?.map(dl=>({
                label:dl?.label,
                name:dl?._id,
                type:dl?.type,
                isForBarcode:dl?.isForBarcode==true,
                orderInBarCode:dl?.orderInBarCode || 0,
                showInLabel:["SKU_ID","SKU_Detail"].includes(dl?._id)
            }))?.filter(dl=>!["paragraph","heading","filter"].includes(dl?.type)),
            templateCode:template?.templateCode || ""
        })
    }

    const { baseLabels: header, baseIds: headerIds, scanFeature: scanFeature } = getHeader(
        allTemplates, tempateData, templateIndex, checkedArray, allHandleChecked, urlPath, checkPermission
    );

    const stockStatus = (data) =>{
        const reOrderLevel =(Number(data?.formData?.avgCount ||0) *Number(data?.formData?.leadTime ||0)) +Number(data?.formData?.minCount || 0)
        if(reOrderLevel<=0) return "Ok Stock"
        if(Number(data?.currentStock || 0)<reOrderLevel){
            return <Typography sx={{color:'red',...textClasses.t13n}}>Low Stock</Typography>
        }
        else {
            if (Number(data?.currentStock || 0) > Number(data?.formData?.maxCount || 0)) {
                return <Typography sx={{ color: 'green', ...textClasses.t13n }}>High Stock</Typography>
            }
            else {
                return <Typography sx={{ ...textClasses.t13n, color: theme.themeOrange }}>Ok Stock</Typography>
            }
        }
    }

    const rows = tempateData && tempateData.map((data, index) => {
        let row = [], currentTemplate = {};
        for (let j = 0; j < headerIds.length; j++) {

            currentTemplate = allTemplates[templateIndex];
            const id = headerIds[j];
            if (id === 'check') {
                if (checkPermission("view") || checkPermission("delete") || checkPermission("edit") || checkPermission("scan")) {
                    row.push(
                        <Typography key={index} sx={{ ...textClasses.t13n, color: theme.themeOrange }}>
                            <Checkbox
                                size='small'
                                sx={{ marginBottom: '4px' }}
                                checked={checkedArray.includes(data._id) ? true : false}
                                onChange={(event) => handleSelect(event, index, data)}
                            />
                        </Typography>,

                    )
                }
            }
            else if (id === 'createdAt') {
                row.push(
                    <Typography key={index} sx={{ ...textClasses.t13n }}>
                        {data?.createdAt && moment(data?.createdAt).format("DD MMM YYYY hh:mm A")}
                    </Typography>
                )
            }
            else if (id === 'stockStatus') {
                row.push(
                    <Typography key={index} sx={{ ...textClasses.t13n }}>
                        {stockStatus(data)}
                    </Typography>
                )
            }
            else if (id === 'currentStock') {
                let allStock = Number(data?.currentStock || 0) + Number(data?.committedStock || 0)


                row.push(
                    // <Tooltip title="Calculated Base on Gate In Shipment" placement="top">
                    <IconButton
                        aria-describedby="mouse-over-popover"
                        aria-owns={Boolean(anchorStock?.anchor || null) ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={(event) => setAnchorStock({ anchor: event?.currentTarget, index })}
                        onMouseLeave={() => setAnchorStock({ anchor: null, index: -1 })}
                        sx={{ color: theme.themeOrange }}
                    >
                        <Typography key={index} sx={{ ...textClasses.t13n, mr: 1 }}>
                            {(allStock || "0") + " " + (data?.formData?.UOM || "")}
                        </Typography>
                        <InfoIcon sx={{ color: theme.themeOrange }} />
                    </IconButton>
                    // </Tooltip>
                )
            }
            else if (id === 'updatedAt') {
                row.push(
                    <Typography key={index} sx={{ ...textClasses.t13n }}>
                        {data?.updatedAt && moment(data?.updatedAt).format("DD MMM YYYY hh:mm A")}
                    </Typography>
                )
            }
            else if (id === 'sNo') {
                row.push(
                    <Typography key={index} sx={{ ...textClasses.t13n, color: theme.themeOrange }}>
                        {((filter.page - 1) * (filter.limit)) + index + 1}
                    </Typography>
                )
            }
            else if (id === 'action') {
                if(checkPermission("view") || checkPermission("delete")   || checkPermission("edit") || checkPermission("scan") || checkPermission("copy")){
                    row.push(
                        < Typography key={index} data-testid="dropdown" sx={{ ...textClasses.t13n }}>
                            <div>
                                <Select value='actions' className='customSelectt' sx={{ fontSize: '13px ! important' }} size='small' disabled={checkedArray && checkedArray.length >= 2 ? true : false}>
                                    <MenuItem value='actions' disabled sx={{ color: "#455A64", ...textClasses.normalText, display: 'none' }}>Action</MenuItem>
                                    {checkPermission("view") && <MenuItem data-testid='view'
                                        onClick={() => { setSelectedRow(data) }}
                                        value="view" sx={{ color: "#455A64", ...textClasses.normalText }}>View </MenuItem>
                                    }
                                    {checkPermission("edit") && <MenuItem data-testid='edit'
                                        onClick={() => { handleAction(data, index, "edit") }}
                                        value="edit" sx={{ color: "#455A64", ...textClasses.normalText }}>Edit </MenuItem>}
                                    {checkPermission("copy") && <MenuItem data-testid='copy'
                                        onClick={() => { handleAction(data, index, "copy") }}
                                        value="copy" sx={{ color: "#455A64", ...textClasses.normalText }}>Copy </MenuItem>}
                                    {checkPermission("delete") && <MenuItem data-testid='delete'
                                        onClick={() => { handleAction(data, index, "Deletes") }}
                                        value="delete" sx={{ color: "#455A64", ...textClasses.normalText }}>Delete </MenuItem>}
                                    {checkPermission("scan") &&
                                        templateIndex >= 0 && allTemplates[templateIndex] && (allTemplates[templateIndex])?.attributes?.filter(f => f.isForBarcode).length > 0 &&
                                        <MenuItem data-testid='scan' onClick={() => handleOpenLabelModal([data._id], currentTemplate)} value="scan" sx={{ color: "#455A64", ...textClasses.normalText }}>Generate Label </MenuItem>
                                    }
                                    {checkPermission("inventoryJourney") &&
                                       <MenuItem data-testid='delete'
                                       onClick={() =>history.push(`/dashboard/data/view-journey?invType=Sku&itemId=${data?._id}&skuId=${data?.formData?.SKU_ID}`)}
                                       value="delete" sx={{ color: "#455A64", ...textClasses.normalText }}>View Journey</MenuItem>
                                    }
                                    {(urlPath == "pallet-master" && checkPermission("attachTag")) &&<MenuItem data-testid='attachTag'
                                        onClick={() => { handleAction(data, index, "attachTag") }}
                                        value="attachTag" sx={{ color: "#455A64", ...textClasses.normalText }}>Attach Tag </MenuItem>}
                                </Select>
                            </div>
                        </Typography >
                    )
                }
            }
            else if(id == "attachedTags"){
                row.push(
                    data?.attachedTags?.length > 0 ? <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, cursor: "pointer" }}
                    onClick={() =>  { handleAction(data, '', "viewAttachedTags") }}
                      >
                    View({data?.attachedTags?.length})
                    </Typography> : "-"
                  )
            }
            else if (id == "currentStatus") {
    
                row.push(<Typography sx={{ ...textClasses.normalText, color: statusMapping[row?.palletStatus]?.color }}>{statusMapping[data?.palletStatus]?.label}</Typography>)
            }
      
            
            // else if(id == "generateLabel"){
            //     if(checkPermission("scan")){
            //         row.push(
            //             <Typography sx={{...textClasses.normalText, cursor: "pointer"}}
            //                 onClick={() => handleOpenLabelModal([data._id], currentTemplate)}
            //             >
            //                 <QrCodeScanner fontSize='inherit' sx={{color: theme.themeOrange, mr: 1}}/>
            //                 Label
            //             </Typography>
            //         )
            //     }
            // }
            else {
                const dynamicCell = currentTemplate?.attributes?.find(_ => _._id === headerIds[j])
                let content;
                switch (dynamicCell?.type) {
                    case "input":
                        content = <Typography sx={{ ...textClasses.t13n }}
                            style={{
                                color: dynamicCell._id === 'SKU_ID' || dynamicCell._id === 'customerID' || dynamicCell._id === 'supplierID' ? '#ff7200' : '#000',
                                cursor: dynamicCell._id === 'SKU_ID' || dynamicCell._id === 'customerID' || dynamicCell._id === 'supplierID' ? 'pointer' : '',
                            }}
                            key={index}
                            onClick={() => handleSelectedRow(data)}>
                            {data?.formData[dynamicCell._id] || '-'}
                        </Typography>
                        break;
                    case "file":
                        content = <Typography sx={{ ...textClasses.t13n }} key={index}>
                            {data?.formData[dynamicCell._id] ?
                                <div onClick={() => handleClick(data?.formData[dynamicCell._id] || '')}> <i style={{
                                    color: '#FF7200',
                                    cursor: 'pointer'
                                }} className='fa fa-eye'></i></div>
                                : "No Img"}

                        </Typography>
                        break;
                    case "select":
                        content = <Typography sx={{ ...textClasses.t13n }} key={index}>
                            {data?.formData[dynamicCell._id] || '-'}
                        </Typography>
                        break;
                    case "number":
                        content = <Typography sx={{ ...textClasses.t13n, }} key={index}>
                            {data?.formData[dynamicCell._id] || '-'}
                        </Typography>
                        break;
                    case "date":
                        content = <Typography sx={{ ...textClasses.t13n, }} key={index}>
                            {data?.formData[dynamicCell._id] || '-'}
                        </Typography>
                        break;
                    case "radio":
                        content = <Typography sx={{ ...textClasses.t13n, }} key={index}>
                            {data?.formData[dynamicCell._id] || '-'}
                        </Typography>
                        break;
                    case "textarea":
                        content = <Typography sx={{ ...textClasses.t13n, }} key={index}>
                            {data?.formData[dynamicCell._id] || '-'}
                        </Typography>
                        break;
                    case "checkbox":
                        content = <Typography sx={{ ...textClasses.t13n, }} key={index}>
                            {data?.formData[dynamicCell._id]?.join(', ') || '-'}
                        </Typography>
                        break;
                    default: content = "-"; break;

                }
                row.push(content)

            }

        }
        if (checkPermission("scan") && scanFeature["label"] && scanFeature["value"]) {
            row.splice(row.length - 3, 0,
                <Typography sx={{ ...textClasses.normalText, cursor: "pointer" }}
                    onClick={() => handleOpenLabelModal([data._id], currentTemplate)}
                >
                    <QrCodeScanner fontSize='inherit' sx={{ color: theme.themeOrange, mr: 1 }} />
                    Label
                </Typography>
            )
        }
        return row;


    })

    let timer;
    const debounce = (func) => {
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            let event = args[0], temp_filter = args[1]
            let value = ""
            if (value) {
                value = value + event.target.value
            }
            else {
                value = event.target.value
            }
            setFilter({ ...temp_filter, "filters": { ...temp_filter.filters, [event.target.name]: value } })

            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 1000);
        };
    };

    const searchHandler = (event, filter) => {
        let temp_filter = { ...filter, page: 1, limit: 10 }
        temp_filter["filters"] = { ...temp_filter["filters"], [event.target.name]: event.target.value }
        get_Master_data(temp_filter, apiKeyword, false, false)
    }

    const optimizedFn = useCallback(debounce(searchHandler), []);


    const handleTabChange = (event, value) => {
        setCheckedArray([])
        setSharedObj({})
        setSelectedRow(null);
        setTabIndex(value)
        setTemplateIndex(value)
        setSelectedTemplates([])
    }

    const get_Master_data=(filter_d,apiKeyword,fetchCount=false,fetchHeader=false)=>{
        setLoader(true)
        setSelectedRow(null)
        let tempFilter = deepCopy_Object(filter_d)
        let temp_tab_index=allTemplates.findIndex(dl=>dl._id==filter_d.template_id) || 0
        setTabIndex(temp_tab_index)
        
        if(filter_d.makeInitial){
            setFilter({...tempFilter,makeInitial:false})
        }
        if(filter_d?.stockStatusFilter?.length >0 ){
             tempFilter["stockStatusFilter"]= filter_d?.stockStatusFilter?.map((item)=>item?.value)
        }
        if(filter_d?.createdAt){
            tempFilter["createdAt"]=filter_d?.createdAt.toISOString()
        }
        dispatch(getItemMaster(tempFilter, apiKeyword, () => {
            setLoader(false)
        }));
        if(fetchCount) dispatch(getMasterTemplateWiseCount({},  modelInfoSingle[urlPath]["countAPI"]))
        if(fetchHeader) dispatch(getTemplateHeader({template_id: tempFilter.template_id}))
    }

    const clearFilter = (template_ID = null, fetchCount = null, fetchHeader = false) => {
        get_Master_data({
            ...finalObj,
            makeInitial: true,
            initial: true,
            template_id: template_ID || filter.template_id,
            page: 1,
            limit: 10
        }, apiKeyword, fetchCount, fetchHeader);
    }
    const viewClearButton=()=>{
        if(filter.createdAt) return true
        if(filter.stockFilter.minStock !== "" || filter.stockFilter.maxStock !== "") return true
        if(filter.stockStatusFilter.length != 0)return true
		for(let key in (filter["filters"] || [])){
			if(filter["filters"][key]){
				return true
			}
		}
		return false
	}

    const dynamicContentGenerator = async (attributes, data, dataKey = 'formData', fetch_html = false) => {
        let arr = []
        for (let dynamicCell of attributes) {
            let content, formData = data[dataKey] || {};
            switch (dynamicCell?.type) {
                case "input":
                    content = formData[dynamicCell._id] || '-'
                    break;
                case "textarea":
                    content = formData[dynamicCell._id] || '-'
                    break;
                case "file":
                    content = formData[dynamicCell._id] || ''
                    break;
                case "select":
                    content = formData[dynamicCell._id] || '-'
                    break;
                case "number":
                    content = formData[dynamicCell._id] || '-'
                    break;
                case "date":
                    content = formData[dynamicCell._id] || ''
                    break;
                case "radio":
                    content = formData[dynamicCell._id] || '-'
                    break;
                case "checkbox":
                    content = formData[dynamicCell._id]?.join(', ') || '-'
                    break;

                default: content = "-"; break;

            }

            if (fetch_html) {
                let modify = content;
                if (dynamicCell?.type == "file") {
                    arr.push(
                        <Typography sx={{ ...textClasses.t12n }}>
                            <ImageIcon />
                        </Typography>
                    )
                    continue
                }
                else if (dynamicCell?.type == "date") {
                    modify = content && dateTimeFormater(content) || "-"
                }
                else {
                    modify = capitalizeSentence(content)
                }
                arr.push(
                    <Typography sx={{ ...textClasses.t12n }}>
                        {modify}
                    </Typography>
                )
                continue
            }
            else {
                if (dynamicCell?.type == "file") {
                    arr.push("")
                    continue
                }
                arr.push(content)
            }
        }
        return arr
    }

    const downloadExcel = async () => {
        ///templateIndex
        ////("downloadExcel");
        let data_array = [], attributes = allTemplates[templateIndex]?.attributes || []

        data_array.push(attributes.map(el => el.label || "Undefined Label"))
        ////("downloadExcel1111",data_array);

        for (let item of tempateData) {
            let output = await dynamicContentGenerator(attributes, item, "formData", false)
            data_array.push(output)
        }
        ////("data_array",data_array);
        return exportToExcel(data_array);
    }


    const getStockSnippent = (currentIndex) => {

        let data = tempateData[currentIndex] || {}
        // console.log(data,"currentIndex",currentIndex)
        let allStock = Number(data?.currentStock || 0) + Number(data?.committedStock || 0)
        return <>
            <Typography sx={{ ...textClasses.boldText, p: 1, color: theme.themeOrange, }}>Physical Stock <span style={{ color: theme.themeBlack }}>({data?.formData?.SKU_ID || ""})</span></Typography>
            <Typography sx={{ ...textClasses.boldText, p: 1, pl: 2, pr: 2, pt: 0, mt: 0 }}>Stock On Hand: <span style={{ color: theme.themeOrange }}>{allStock}</span></Typography>
            <Typography sx={{ ...textClasses.boldText, p: 1, pl: 2, pr: 2, pt: 0, mt: 0 }}>Committed Stock: <span style={{ color: theme.themeOrange }}>{Number(data?.committedStock || 0)}</span></Typography>
            <Typography sx={{ ...textClasses.boldText, p: 1, pl: 2, pr: 2, pt: 0, mt: 0 }}>Available At Warehouse: <span style={{ color: theme.themeOrange }}>{Number(data?.currentStock || 0)}</span></Typography>
        </>
    }

    useEffect(() => {
        if (urlPath) {
            setLoader(true)
            const bodyData = {
                status:"published",type: "wms",
                templateType: modelInfoSingle[urlPath]["templateType"],
                approved:true,fetchAttributes:true,dataEntryType: modelInfoSingle[urlPath] &&  modelInfoSingle[urlPath]["dataEntryType"] || "",
                projection:{attributes:1,dataEntryType:1,name:1,templateCode:1}
            }
            dispatch(getAllTemplates(bodyData, (output) => {
                if (!output || output.length == 0) {
                    setLoader(false)
                }
                else {
                    dispatch(getMasterTemplateWiseCount({}, modelInfoSingle[urlPath]["countAPI"], () => {
                        // setLoader(false)
                    }))
                }
            }))
            setTemplateIndex(0)
            setTabIndex(0)
            handleSelectedRow(null)
            setCheckedArray([])
            setSelectedTemplates([])
        }
        return(()=>{
            dispatch({ type: "GET_ITEM_MASTER", payload: {} });
        })
    }, [urlPath])


    useEffect(() => {
        return () => {
          dispatch(resetStoreState());  // Reset on component unmount
        };
      }, [dispatch]);

      
    useEffect(() => {
        if (allTemplates.length > 0 && templateIndex>=0) {
            let template_id = allTemplates[templateIndex]?._id
            get_Master_data({ ...finalObj, template_id, makeInitial: true }, apiKeyword, false, true)

        }
    }, [allTemplates, templateIndex])

    useEffect(() => {
        if (!filter.initial) {
            get_Master_data(filter, apiKeyword, false)
        }
    }, [filter.page,filter.limit,filter.createdAt,filter.stockFilter,filter.stockStatusFilter])

    useEffect(() => {
        if (selectedRow) {
            let apiKeyword =['item-master',"kit-master","pallet-master"].includes(urlPath) ? "viewItemDetail" : '' || urlPath === 'customer-master' ? "viewCustomerDetail" : '' || urlPath === 'supplier-master' ? "viewSupplierDetail" : ''
            let apiObjKey =['item-master',"kit-master","pallet-master"].includes(urlPath) ? "item_templateId" : '' || urlPath === 'customer-master' ? "customer_templateId" : '' || urlPath === 'supplier-master' ? "supplier_templateId" : ''
            setCardLoader(true)
            dispatch(getItemMasterDetail({ [apiObjKey]: selectedRow._id }, apiKeyword, () => {
                setCardLoader(false)
            }))
        }
    }, [selectedRow])

  


    return (
        <>

            <Grid container>
                <Grid item sm={12} xs={12}>
                    <Grid container>
                        <Grid item sm={6} xs={4}>
                            <Typography sx={{ ...textClasses.cardTitle }}>
                            {titleMap[urlPath] || ''}
                            </Typography>
                        </Grid>
                        {allTemplates?.length > 0 && (checkPermission("add") ||  tempateData?.length > 0) && <Grid
                            container
                            item
                            sm={6}
                            justifyContent="end"
                            alignItems="end"
                            gap={1}
                        >
                            {checkPermission("add") &&
                                <Button
                                    data-testid='add'
                                    variant="contained"
                                    size="small"
                                    startIcon={<Add />}
                                    sx={{ ...buttonClasses.lynkitOrangeEmpty, textTransform: 'none' }}
                                    onClick={() => { handleAction('', '', "add") }}
                                >
                                    Add New
                                </Button>
                            }
                            {
                                checkPermission("add") &&
                                <Button
                                    data-testid='upload'
                                    variant="contained"
                                    size="small"
                                    startIcon={<PublishIcon />}
                                    sx={{
                                        ...buttonClasses.lynkitOrangeFill,
                                        textTransform: 'none'
                                    }}
                                    onClick={() => { handleAction('', '', "upload") }}
                                >
                                    Upload
                                </Button>
                            }
                            {tempateData?.length > 0 ?
                                <Button
                                    data-testid='export'
                                    variant="contained"
                                    size="small"
                                    startIcon={<DownloadIcon />}
                                    onClick={() => downloadExcel()}
                                    sx={{ ...buttonClasses.lynkitBlackEmpty, textTransform: 'none' }}
                                >
                                    Export
                                </Button>
                                : ""
                            }
                        </Grid>}
                    </Grid>
                    <FileModal openModal={openModal} displayUrl={displayUrl} setOpenModal={setOpenModal} />
                </Grid>

                <>
                    {allTemplates?.length > 0 ? <Box
                        sx={{ borderBottom: 1, bgcolor: 'background.paper', borderColor: 'divider', mt: 0, mb: 2 }}>
                        <Tabs
                            value={tabIndex}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            className='tabsClass'
                            aria-label="scrollable auto tabs example"
                            sx={{ maxWidth: window.innerWidth - 108, '& .MuiTabs-indicator': { backgroundColor: theme.themeOrange }, "& .MuiTab-root.Mui-selected": { color: theme.themeOrange } }}
                        >
                            {allTemplates.map((item, index) => {
                                return (
                                    <Tab
                                        key={index}
                                        // label={`${item.name.toLowerCase()} (${item.frequency})` }
                                        label={`${item.name} (${mastertemplateWiseCount?.data && mastertemplateWiseCount?.data[item._id] || 0})`}
                                        {...a11yProps(index)}
                                        sx={{ ...textClasses.normalText, textTransform: "capitalize" }}
                                        variant="scrollable"
                                        scrollButtons
                                        allowScrollButtonsMobile
                                        aria-label="scrollable force tabs example"

                                    />
                                )
                            })}
                        </Tabs>

                    </Box> : ""}

                    {mastertemplateWiseCount?.data && mastertemplateWiseCount?.data[filter.template_id] > 0 ?
                        <Grid container spacing={1} sx={{ display: 'flex', alignItems: 'center'}}>
                            {allTemplates ? allTemplates[templateIndex]?.attributes?.slice(0, 3).map((item, index) => {
                                if (item.type === 'input') {
                                    return (
                                        <Grid container item key={index} xs={12} sm={6} md={3} lg={1.8}>
                                            <FormControl fullWidth variant="outlined" size="small" sx={{ ...selectStylesOverride }}>
                                                <OutlinedInput
                                                    data-testid='search-input'
                                                    fullWidth
                                                    name={`${item._id}`}

                                                    onChange={(e) => optimizedFn(e, filter)}
                                                    // onChange={(e) => handleChange(e, 'input')}
                                                    sx={{
                                                        fontSize: "12px",
                                                        ...selectStylesOverride,
                                                        "& input::placeholder": {
                                                            fontSize: "12px",
                                                        },
                                                    }}
                                                    value={filter?.filters[item._id] || ""}
                                                    placeholder={`Search by ${item.label}`}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <Search fontSize="small" />
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                    )
                                }
                            }) : []}
                            {allTemplates?.length > 0 &&
                                <Grid item xs={12} sm={6} md={3} lg={1.8}>
                                    <FormControl sx={{ ...selectStylesOverride, width: '100%' }}>
                                        <DatePicker
                                            label="Created At"
                                            className='customDatePicker'
                                            onChange={(newValue) => {
                                                setFilter((f) => ({
                                                    ...f,
                                                    createdAt: newValue,
                                                    initial: false,
                                                    page: 1,
                                                    limit: 10
                                                }));
                                            }}
                                            slotProps={{ textField: { size: "small" } }}
                                            format="DD/MM/YYYY"
                                            name='createdAt'
                                            value={filter.createdAt}
                                        />
                                    </FormControl>
                                </Grid>}
                            {['item-master',"kit-master"].includes(urlPath) &&      
                            <Grid item xs={12} sm={6} md={3} lg={1.8}>
                                <FormControl
                                    size="small"
                                    fullWidth
                                >
                                    <Autocomplete
                                        data-testid="select-status-filter"
                                        multiple
                                        labelId="action"
                                        size='small'
                                        sx={{
                                            fontSize: "12px",
                                            ...selectStylesOverride,
                                            "& input::placeholder": {
                                                fontSize: "12px",
                                            },
                                            ml: 2
                                        }}
                                        limitTags={1}
                                        options={stockStatusFilterOptions}
                                        onChange={(event, option) => handleSelectionChange(option)}
                                        disableCloseOnSelect
                                        renderOption={(props, option, { selected }) => {
                                            const { value, label } = option
                                            if (value == "allStock") {
                                                return (
                                                    <ListSubheader key={value} sx={{ mt: 0, pt: 0 }}>
                                                        <Checkbox
                                                            onChange={(e) => {
                                                                handleSelectionChange([], true)
                                                            }}
                                                            checked={stockStatusFilterOptions.filter(dl => dl?.value != "allStock")?.length == filter?.stockStatusFilter?.length}
                                                        />
                                                        {label}
                                                    </ListSubheader>
                                                )
                                            }
                                            else {
                                                return (
                                                    <MenuItem value={value} key={value}  {...props}>
                                                        <Checkbox
                                                            checked={selected}
                                                        />
                                                        <ListItemText primary={label} />
                                                    </MenuItem>
                                                )
                                            }
                                        }
                                        }
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        ...selectStylesOverride,
                                                        color: theme.themeOrange,
                                                    }}
                                                    label={<Typography sx={{ fontSize: "14px" }}>Stock Status</Typography>}
                                                    placeholder='Stock Status'
                                                />
                                            )
                                        }
                                        }
                                        value={filter?.stockStatusFilter || []}
                                    />
                                </FormControl>
                            </Grid>}
                         {['item-master',"kit-master"].includes(urlPath) && <Grid item xs={12} sm={6} md={3} lg={1.8}>
                                <Button
                                    data-testid="stockRangeFilter"
                                    aria-describedby={id1}
                                    onClick={handleClick1}
                                    size="medium"
                                    fullWidth
                                    sx={{
                                        ...buttonClasses.medium,
                                        boxShadow: 'none',
                                        '&:hover': {
                                            border: "1px solid #FF7200",
                                            backgroundColor: "white",
                                            boxShadow: "none",
                                        }
                                    }}

                                    endIcon={anchorEl1 === null ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}

                                ><Typography sx={{ ...textClasses.normalText, fontSize: "16px", marginRight: '40px' }}
                                > {filter?.stockFilter?.minStock || filter?.stockFilter?.maxStock ?
                                    `Stock (${filter?.stockFilter?.minStock} - ${filter?.stockFilter?.maxStock})`
                                    : "Current Stock"}
                                    </Typography></Button>

                                <Popover
                                    id={id1}
                                    open={open1}
                                    anchorEl={anchorEl1}
                                    onClose={handleClose1}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <GenericRangeFilter
                                        minLabel="Min Stock"
                                        maxLabel="Max Stock"
                                        initialState={{ start: filter.stockFilter?.minStock, end: filter?.stockFilter?.maxStock }}
                                        handleRangeChange={(r) => {
                                            setAnchorEl1(null)
                                            setFilter({
                                                ...filter, initial: false,
                                                stockFilter: {
                                                    minStock: r.start,
                                                    maxStock: r.end
                                                }
                                            })
                                        }}
                                    />
                                </Popover>
                            </Grid> }
                            {viewClearButton() ?
                                <Grid container item xs={1.8} alignItems={"center"}>
                                    <Typography
                                        sx={{
                                            ...textClasses.boldText,
                                            color: theme.themeOrange,
                                            textDecoration: "underline", cursor: "pointer", textTransform: "none", position: "absolute",
                                            right: '26px', marginTop: '6px'
                                        }}
                                        onClick={() => clearFilter()}
                                    >
                                        Clear Filter(s)
                                    </Typography>
                                </Grid>
                                : ''}

                        </Grid>
                        : ""
                    }


                    <Grid>
                        {checkedArray.length > 0 ?
                            <Box mt={1} data-testid='edit'>
                                {checkPermission("edit") && checkedArray.length <= 2 ?
                                    <Button
                                        size='small'
                                        data-testid='edit'
                                        variant="contained"
                                        className='UM_uploadfile-btn'
                                        onClick={() => { handleAction(sharedObj, '', "edit") }}
                                        disabled={checkedArray.length >= 2 ? true : false}
                                        sx={{ fontSize: "12px", borderRadius: "8px", margin: "5px", color: "black", border: "1px solid black", ...buttonClasses.lynkitOrangeFill, mr: 1, textTransform: "none" }}
                                    > Edit </Button>
                                    : ''}
                                {checkPermission("view") && checkedArray.length <= 2 ?
                                    <Button
                                        size='small'
                                        data-testid="view"
                                        variant="contained"
                                        className='UM_uploadfile-btn'
                                        onClick={() => { setSelectedRow(sharedObj) }}
                                        disabled={checkedArray.length >= 2 ? true : false}
                                        sx={{ fontSize: "12px", borderRadius: "8px", margin: "5px", color: "black", border: "1px solid black", ...buttonClasses.lynkitOrangeFill, mr: 1, textTransform: "none" }}
                                    > View </Button>
                                    : ''}
                                {checkPermission("inventoryJourney") && (['item-master',"kit-master"].includes(urlPath)) && checkedArray.length <= 2 ?
                                    <Button
                                        size='small'
                                        data-testid="view"
                                        variant="contained"
                                        className='UM_uploadfile-btn'
                                        onClick={() =>history.push(`/dashboard/data/view-journey?invType=Sku&itemId=${sharedObj?._id}&skuId=${sharedObj?.formData?.SKU_ID}`)}
                                        disabled={checkedArray.length >= 2 ? true : false}
                                        sx={{ fontSize: "12px", borderRadius: "8px", margin: "5px", color: "black", border: "1px solid black", ...buttonClasses.lynkitOrangeFill, mr: 1, textTransform: "none" }}
                                    > View Journey</Button>
                                    : ''}
                                {
                                    checkPermission("copy") &&
                                        urlPath == "kit-master" &&
                                        checkedArray.length <= 2 ?
                                        <Button
                                            size='small'
                                            data-testid="view"
                                            variant="contained"
                                            className='UM_uploadfile-btn'
                                            onClick={() => { handleAction(sharedObj, '', "copy") }}
                                            disabled={checkedArray.length >= 2 ? true : false}
                                            sx={{ fontSize: "12px", borderRadius: "8px", margin: "5px", color: "black", border: "1px solid black", ...buttonClasses.lynkitOrangeFill, mr: 1, textTransform: "none" }}
                                        > Copy </Button>
                                        : ''}
                                {
                                    (checkPermission("attachTag") &&
                                    urlPath == "pallet-master") &&
                                        checkedArray.length <= 2 ?
                                        <Button
                                            size='small'
                                            data-testid="view"
                                            variant="contained"
                                            className='UM_delete-btn'
                                            onClick={() =>  { handleAction(sharedObj, '', "attachTag") }}
                                            disabled={checkedArray.length >= 2 ? true : false}
                                            sx={{ fontSize: "12px", borderRadius: "8px", margin: "5px", color: "black", border: "1px solid black", mr: 1, textTransform: "none" }}
                                        > Attach RFID Tags </Button>
                                        : ''}
                                {checkPermission("delete") && <Button
                                    size='small'
                                    data-testid="delete"
                                    variant="contained"
                                    className='UM_delete-btn'
                                    onClick={() => { handleAction(sharedObj, '', "Delete") }}
                                    sx={{ fontSize: "12px", borderRadius: "8px", margin: "5px", color: "black", border: "1px solid black", mr: 1, textTransform: "none" }}
                                > Delete</Button>}
                                {
                                    checkPermission("scan") &&
                                    templateIndex >= 0 && allTemplates[templateIndex] && (allTemplates[templateIndex])?.attributes?.filter(f => f.isForBarcode).length > 0 &&

                                    <Button
                                        size='small'
                                        data-testid='edit'
                                        variant="contained"
                                        className='UM_uploadfile-btn'
                                        onClick={() => handleOpenLabelModal(checkedArray, allTemplates[templateIndex])}
                                        sx={{ fontSize: "12px", borderRadius: "8px", margin: "5px", color: "black", border: "1px solid black", ...buttonClasses.lynkitOrangeFill, mr: 1, textTransform: "none" }}
                                    >
                                        Generate Label
                                    </Button>
                                }
                            </Box>
                            : ''}
                    </Grid>


                    {loader
                        ? <Grid data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                            <Grid item><GenericLoader /></Grid>
                        </Grid>
                        :
                        <Grid container sx={{ mt: 2 }}>
                            <Grid 
                            item 
                            md={selectedRow ? 8 : 12} 
                            sm={12} xs={12} order={{xs:2,sm:2,md:1}} 
                            sx={{display:{xs:selectedRow?"none":"visible",sm:selectedRow?"none":"visible",md:"unset"}}}
                            >
                                <Table
                                    header={header}
                                    rows={rows}
                                    totalRows={totalRows}
                                    filter={filter}
                                    urlPath={urlPath}
                                    setFilter={setFilter}
                                    checkPermission={checkPermission}
                                    scanFeature={scanFeature}
                                />
                            </Grid>

                            {
                                selectedRow &&
                                <Grid item md={4} sm={12} xs={12} order={{xs:1,sm:1,md:2}} sx={{ p: 1, pt: 0 }}>
                                    <DetailCard
                                        cardLoader={cardLoader}
                                        data={ItemDetail}
                                        urlPath={urlPath}
                                        selectedRow={selectedRow}
                                        header={header || []}
                                        headerIds={headerIds || []}
                                        allTemplates={allTemplates}
                                        templateIndex={templateIndex}
                                        handleSelectedRow={handleSelectedRow}
                                        tempateData={tempateData}
                                        handleClick={handleClick}
                                        stockStatus={stockStatus}
                                        actionStatus={actionStatus}
                                        setActionStatus={setActionStatus}
                                        handleAction={handleAction}
                                        checkPermission={checkPermission}
                                    />
                                </Grid>
                            }


                        </Grid>

                    }
                    {labelModal?.data?.length>0 && <Label
                        labelData={labelModal}
                        showModal={labelModal != null}
                        handleCloseModal={() => setLabelModal(null)}
                        labelType="entityMaster"
                    />}
                    <Dialog
                        open={detailModal != null}
                        onClose={handleCloseDetailModal}
                        scroll={"paper"}
                        PaperProps={{ sx: { width: "100%" } }}
                        maxWidth={"lg"}
                    >
                        <DialogContent>
                            <DetailModal detailModal={detailModal} handleCloseDetailModal={handleCloseDetailModal} />
                        </DialogContent>
                    </Dialog>
                </>
            </Grid>


            {actionStatus=="attachTag" &&
                <RFIDMappingModal
                    viewModal={actionStatus}
                    setViewModal={setActionStatus}
                    searchTitle={allTemplates?.at(0)?.attributes?.at(0)?.label}
                    sharedObj={sharedObj}
                    clearFilter={clearFilter} />
            }
        

            {
                ViewEditState && <AddViewEdit
                    open={ViewEditState}
                    hide={(hideType) => {
                        setViewEditState(false)
                        setSelectedRow(null)
                        if(hideType=="success"){
                            setSelectedTemplates([])
                            setCheckedArray([])
                        }
                    }}
                    clearFilter={clearFilter}
                    action={actionStatus}
                    sharedObj={sharedObj}
                    urlPath={urlPath}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    templateIndex={templateIndex}
                    setCheckedArray={setCheckedArray}
                />
            }

            {
                deleteStatus &&
                <DeleteItem
                    open={deleteStatus}
                    hide={() => {
                        setDeleteStatus(false)
                        setSelectedRow(null)
                        setSelectedTemplates([])
                    }}
                    clearFilter={clearFilter}
                    action={actionStatus}
                    selectedTemplates={selectedTemplates}
                    urlPath={urlPath}
                    setDeleteStatus={setDeleteStatus}
                    setSelectedTemplates={setSelectedTemplates}
                    setCheckedArray={setCheckedArray}
                />
            }
            {
                uploadStatus &&
                <UploadItem
                    open={uploadStatus}
                    hide={() => {
                        setUploadStatus(false)
                        setSelectedRow(null)
                        setSelectedTemplates([])
                    }}
                    action={actionStatus}
                    urlPath={urlPath}
                    clearFilter={clearFilter}
                    templateIndex={templateIndex}
                    finalObj={finalObj}
                />
            }
            {
                actionStatus == "viewAttachedTags" &&
                <ViewAttachedTagsModal 
                viewModal={actionStatus}
                setViewModal={setActionStatus}
                sharedObj={sharedObj}
                />
            }
            {/* current Stock popover */}
            {Boolean(anchorStock?.anchor || null) && anchorStock?.index >= 0 &&
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={Boolean(anchorStock?.anchor || null) ? true : false}
                    anchorEl={anchorStock?.anchor || null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={() => setAnchorStock({ anchor: null, index: -1 })}
                    disableRestoreFocus
                >
                    {getStockSnippent(anchorStock?.index)}
                </Popover>
            }

        </>
    )
}

const DetailModal = ({ detailModal, handleCloseDetailModal }) => {

    const title = detailModal ? detailModal.title : "Loading...";
    const modalType = detailModal ? detailModal.modalType : null;
    const data = detailModal ? detailModal.data : null;
    const [packageFilter, setPackageFilter] = useState(data ? data.packageId : "");
    const [itemFilter, setItemFilter] = useState("");

    const handlePackageChange = (e) => {
        setPackageFilter(e.target.value);
    }

    const handleItemChange = (e) => {
        setItemFilter(e.target.value);
    }

    return (
        <>
            {
                detailModal &&
                <>
                    <Grid container>
                        <Grid container item sm={12} xs={12} alignItems="center" justifyContent={"end"}>
                            <IconButton sx={{ mb: 1 }} onClick={handleCloseDetailModal}>
                                <Close />
                            </IconButton>
                        </Grid>
                        <Grid item sm={4} xs={6}>
                            <Typography sx={{
                                ...textClasses.cardTitle,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>{title}</Typography>
                        </Grid>
                        <Grid item sm={8} xs={6}>
                            {
                                modalType == "itemDetail" &&
                                <TextField size='small' sx={{ ...inputClasses.shadowField, float: "right", ml: 2 }} placeholder="Search Item Id" value={itemFilter} onChange={handleItemChange} />
                            }
                            {
                                data && data.isPackageExist &&
                                <TextField size='small' sx={{ ...inputClasses.shadowField, float: "right" }} placeholder="Search Package Id" value={packageFilter} onChange={handlePackageChange} />
                            }


                        </Grid>
                    </Grid>
                </>
            }

        </>
    )
}


const DetailCard = ({ data, handleSelectedRow, header, headerIds, allTemplates, templateIndex, cardLoader, urlPath, handleClick,stockStatus,actionStatus,setActionStatus,handleAction,checkPermission}) => {
    const dispatch = useDispatch();
    // ////("payload",{header,data,headerIds});
    const { AllUserData } = useSelector((state) => state.users);
    let userInfo = (data && AllUserData && AllUserData.data) ? AllUserData.data.filter(u => {
        if (data.created_by && data.created_by._id) {
            return u._id == data.created_by._id
        }
        return false;
    }) : []
    userInfo = userInfo.length > 0 ? userInfo[0] : null;
    const cardTitle = (() => {
        let idFind;
        if(['item-master',"kit-master","pallet-master"].includes(urlPath)){
            idFind = "SKU_ID"
        }
        else if (urlPath == "customer-master") {
            idFind = "customerID"
        }
        else if (urlPath == "supplier-master") {
            idFind = "supplierID"
        }
        try {
            let ind = allTemplates[templateIndex].attributes.map(h => h._id).indexOf(idFind);
            if (ind >= 0) {
                return allTemplates[templateIndex].attributes[ind].label
            }
            return "";
        }
        catch (e) {
            return "";
        }

    })();

    useEffect(() => {
        dispatch(getAllUsers({ "isAdminIncluded": true }))

        // console.log("data",data);
    }, [data])

    const DetailRow = ({ title, children }) => {
        return (
            <Grid container sx={{ mt: 1 }}>
                <Grid item sm={6} xs={4}>
                    <Typography sx={{ ...textClasses.t12n, color: "#827878", wordWrap: "break-word", mr: 1 }}>{title}: </Typography>
                </Grid>
                <Grid container item sm={6} xs={8} direction="row" alignItems={"center"} sx={{ wordWrap: "break-word", wordBreak: "break-all" }}>
                    {children}
                </Grid>
            </Grid>
        )
    }
    return (
        <>
            {cardLoader
                ? <Grid data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                    <Grid item><GenericLoader /></Grid>
                </Grid>
                :
                data &&
                <Card className='ppmodal' data-testid='genericdetailedtable' sx={{ ...cardClasses.basic, borderRadius: "8px", border: `1px solid ${theme.themeOrange}`, position: "relative" }}>
                    <CardHeader
                        sx={{ height: "46px", backgroundColor: theme.themeOrange }}
                        title={
                            <Typography sx={{ ...textClasses.boldText, color: "white", fontSize: "16px" }}>
                                <SellOutlined fontSize='inherit' sx={{ mr: 1 }} />
                                {
                                    ['item-master',"kit-master","pallet-master"].includes(urlPath) ? `${cardTitle} - ` : '' || urlPath === 'customer-master' ? `${cardTitle} - ` : '' || urlPath === 'supplier-master' ? `${cardTitle} - ` : ''
                                }
                                {['item-master',"kit-master","pallet-master"].includes(urlPath) ? data?.formData?.SKU_ID || '-' : '' || urlPath === 'customer-master' ? data?.formData?.customerID || '-' : '' || urlPath === 'supplier-master' ? data?.formData?.supplierID || '-' : ''}

                            </Typography>
                        }
                        action={
                            <IconButton size='small' sx={{ p: 0, mb: 1 }} onClick={() => handleSelectedRow(null)}>
                                <Close sx={{ color: "white" }} />
                            </IconButton>
                        }
                    />

                    <CardContent sx={{ height: '400px', overflowY: "scroll" }}>
                            {['item-master',"kit-master","pallet-master"].includes(urlPath) ?
                                <DetailRow title='SKU Type'>
                                    <Typography sx={{ ...textClasses.t12n,textTransform:"capitalize" }}>
                                        {data?.dataEntryType || "N/A"}
                                    </Typography>
                                </DetailRow>
                                : ""
                            }
                            {['item-master'].includes(urlPath)  ? <DetailRow title="Rack Family">
                                <Typography sx={{ ...textClasses.t12n }}>
                                    { data?.mappingArea?.length > 0 ? data?.mappingArea?.join(", "): "-"}
                                </Typography>
                            </DetailRow>
                                : ""
                                }

                            {['pallet-master'].includes(urlPath) ? <DetailRow title="Current Status">
                                <Typography sx={{ ...textClasses.t12n }}>{data?.palletStatus || '-'}</Typography>
                            </DetailRow>
                                : ""
                            }  
                            {(['pallet-master'].includes(urlPath) && checkPermission("attachTag")) &&
                                data?.attachedTags ?
                                <DetailRow title="Attached Tags">
                                    {
                                        data?.attachedTags?.length > 0 ?

                                            <Typography
                                                sx={{
                                                    ...textClasses.t12n,
                                                    color: theme.themeOrange,
                                                    textDecoration: "underline",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => { handleAction(data, '', "viewAttachedTags") }}
                                            >
                                                View({data?.attachedTags?.length})
                                            </Typography> :
                                            "-"
                                    }
                                </DetailRow>
                                : ""
                            }
                            {['item-master',"kit-master"].includes(urlPath) ? <DetailRow title='Stock In Warehouse'>
                                <Typography sx={{ ...textClasses.t12n }}>
                                    {(data?.currentStock || "0") +" "+ (data?.formData?.UOM || "")}
                                </Typography>
                            </DetailRow>
                                : ""
                                }
                            {['item-master',"kit-master"].includes(urlPath) ? <DetailRow title='Committed Stock'>
                                <Typography sx={{ ...textClasses.t12n }}>
                                    {(data?.committedStock || "0") +" "+ (data?.formData?.UOM || "")}
                                </Typography>
                            </DetailRow>
                                : ""
                                }

{/* <Divider/> */}
                            {['item-master',"kit-master"].includes(urlPath) ? <DetailRow title='Stock Status'>
                                <Typography sx={{ ...textClasses.t12n }}>
                                    {stockStatus(data)}
                                </Typography>
                            </DetailRow>
                                : ""
                                }
                            {
                               data?.uniqueComponentsCount  ?
                                <DetailRow title="Total Component">
                                    <Typography
                                        sx={{
                                            ...textClasses.t12n,
                                            color: theme.themeOrange,
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            setActionStatus("viewComponent")
                                        }
                                    >
                                        View Components({data?.uniqueComponentsCount})
                                    </Typography>
                                </DetailRow> : ""
                            }
                        {headerIds.map((id, i) => {
                            const currentTemplate = allTemplates[templateIndex];
                            let row = [];
                            if (id === 'check' || id === 'action' || id === 'sNo' || id === 'createdAt') {
                                return null
                            }

                            else if (id === 'updatedAt') {
                                return (
                                    <DetailRow title='Updated At'>
                                        <Typography sx={{ ...textClasses.t12n }}>
                                            {moment(data?.updatedAt).format("DD MMM YYYY hh:mm A")}
                                        </Typography>
                                    </DetailRow>
                                )
                            }

                            else {
                                const dynamicCell = currentTemplate?.attributes?.find(_ => _._id === id)
                                let content;
                                switch (dynamicCell?.type) {
                                    case "input":
                                        content = <DetailRow title={header.slice(0, -1)[i]}>
                                            <Typography sx={{ ...textClasses.t12n }}>
                                                {capitalizeSentence(data?.formData[dynamicCell._id] || '-')}
                                            </Typography>
                                        </DetailRow>
                                        break;
                                    case "textarea":
                                        content = <DetailRow title={header.slice(0, -1)[i]}>
                                            <Typography sx={{ ...textClasses.t12n }}>
                                                {capitalizeSentence(data?.formData[dynamicCell._id] || '-')}
                                            </Typography>
                                        </DetailRow>
                                        break;
                                    case "file":
                                        content = <DetailRow title={header.slice(0, -1)[i]}>
                                            {data?.formData[dynamicCell._id] ?
                                                <div onClick={() => handleClick(data?.formData[dynamicCell._id] || '')}>   <span style={{
                                                    ...textClasses.t14n,
                                                    textDecoration: 'underline',
                                                    color: '#FF7200',
                                                    cursor: "pointer"

                                                }}>View File</span></div>
                                                : "No Img"}
                                        </DetailRow>
                                        break;
                                    case "select":
                                        content = <DetailRow title={header.slice(0, -1)[i]}>
                                            <Typography sx={{ ...textClasses.t12n }}>
                                                {capitalizeSentence(data?.formData[dynamicCell._id] || '-')}
                                            </Typography>
                                        </DetailRow>
                                        break;
                                    case "number":
                                        content = <DetailRow title={header.slice(0, -1)[i]}>
                                            <Typography sx={{ ...textClasses.t12n }}>
                                                {data?.formData[dynamicCell?._id] || '-'}
                                            </Typography>
                                        </DetailRow>
                                        break;
                                    case "date":
                                        content = <DetailRow title={header.slice(0, -1)[i]}>
                                            <Typography sx={{ ...textClasses.t12n }}>
                                                {data?.formData[dynamicCell?._id] || '-'}
                                            </Typography>
                                        </DetailRow>
                                        break;
                                    case "radio":
                                        content = <DetailRow title={header.slice(0, -1)[i]}>
                                            <Typography sx={{ ...textClasses.t12n }}>
                                                {capitalizeSentence(data?.formData[dynamicCell._id] || '-')}
                                            </Typography>
                                        </DetailRow>
                                        break;
                                    case "checkbox":
                                        content = <DetailRow title={header.slice(0, -1)[i]}>
                                            <Typography sx={{ ...textClasses.t12n }}>
                                                {capitalizeSentence(data?.formData[dynamicCell._id]?.join(', ') || '-')}
                                            </Typography>
                                        </DetailRow>
                                        break;

                                    default: content = " "; break;

                                }
                                row.push(content)

                            }
                            return row;
                        })
                        }

                        <DetailRow title='Created By'>
                            <Typography sx={{ ...textClasses.t12n }}>
                                {userInfo && userInfo.name} , {userInfo && userInfo.designation}
                            </Typography>
                        </DetailRow>
                        <DetailRow title='Created At'>
                            <Typography sx={{ ...textClasses.t12n }}>
                                {data.createdAt && dateTimeFormater(data.createdAt)}
                            </Typography>
                        </DetailRow>
                            { actionStatus == "viewComponent" && 
                                   <ConnectedComponentsTable
                                   viewModal={actionStatus}
                                   setViewModal={setActionStatus}
                                   sharedObj={data} />}
                    </CardContent>
                    <Grid container sx={{ position: "absolute", bottom: "10px" }}>
                        <hr />
                        <Grid
                            container
                            direction={"row"}
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            {
                                    (urlPath == "pallet-master" && checkPermission("attachTag")) &&
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{ ...buttonClasses.lynkitOrangeFill ,ml:2}}
                                        onClick={() =>  { handleAction(data, '', "attachTag") }}
                                    >
                                        <LocalOfferIcon sx={{ fontSize: 15, mr: 1 }} />
                                        RFID Mapping
                                    </Button>
                            }
                            {/* <Button size='small' variant='contained' sx={{...buttonClasses.lynkitOrangeFill, ml: 2}}
                                onClick={() => handleOpenRfidModal({
                                    shipmentId: data.shipmentId
                                })}
                            >
                                <Sell fontSize='inherit' sx={{color: "white", mr: 1}} />
                                RFID Mapping
                            </Button> */}
       {/* <Button
                size="small"
                variant="contained"
                sx={{
                  ...buttonClasses.lynkitOrangeEmpty,
                  borderRadius: "8px",
                  ml: 2,
                }}
                onClick={handleOpenMenu}
              >
                Action <ArrowDropDown />
              </Button> */}
                        </Grid>
                    </Grid>
                </Card>
            }
        </>
    )
}


const Table = ({ header, rows, filter, setFilter, totalRows, checkPermission, scanFeature, urlPath = "" }) => {
    const getHeader = () => {
        let maxCol=7,headerSlice=3
        if(['item-master',"kit-master","pallet-master"].includes(urlPath)){
            // maxCol++
            headerSlice = headerSlice + 2
        }
        let maxFrontHead = Math.min(header.length - headerSlice, maxCol)
        let temphead = header.slice(0, maxFrontHead)
        temphead.push(...(header.slice(-headerSlice) || []))
        if (checkPermission("scan") && scanFeature["label"] && scanFeature["value"]) {
            temphead.splice(temphead?.length - 3, 0, scanFeature["label"])
        }
        // console.log("header",temphead);
        return temphead
    }
    const getRows = () => {
        // //("getRows",{rows});
        // return rows
        let allData = []
        for(let item of rows){
            let sliceLength=3,maxCol=7
            if(['item-master',"kit-master","pallet-master"].includes(urlPath)){
                // maxCol++
                sliceLength = sliceLength + 2
            }
            if (checkPermission("scan") && scanFeature["label"] && scanFeature["value"]) {
                sliceLength++
            }
            let maxFrontHead = Math.min(header.length - sliceLength, maxCol)
            let temphead = item.slice(0, maxFrontHead)
            temphead.push(...(item.slice(-sliceLength) || []))
            allData.push(temphead)
        }
        // //("getRows",{allData});
        return allData
    }
    return (
        <>
            {rows && rows.length > 0
                ?

                <GenericTable
                    header={getHeader()}
                    rows={getRows()}
                    pageCount={totalRows}
                    pageNumber={filter.page - 1}
                    handleChangePage={(event, pagevalue) => {
                        setFilter((f) => ({
                            ...f,
                            page: pagevalue + 1,
                            initial: false
                        }));
                    }}
                    handleChangeRowsPerPage={(event) => {
                        setFilter((f) => ({
                            ...f,
                            page: 1,
                            limit: +event.target.value,
                            initial: false
                        }));
                    }}
                    rowsPerPage={filter.limit}
                />
                // <Grid sx={{ width: "100%" }}>
                //     <table
                //         style={{
                //             width: "100%"
                //         }}
                //     >
                //         <thead style={{ backgroundColor: theme.themeGray }}>
                //             <tr>
                //                 {
                //                     header.slice(0, 2).map((head, i) => {
                //                         return (
                //                             <td style={{ padding: "10px 6px 10px 6px", ...textClasses.boldText, textAlign: "left", overflowWrap: "break-word" }}>{head}</td>
                //                         )

                //                     })
                //                 }
                //                 {
                //                     header.slice(2, 6).map((head, i) => {
                //                         if (["Created At", "Updated At"].includes(head)) return null
                //                         return (
                //                             <td style={{ padding: "10px 6px 10px 6px", ...textClasses.boldText, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: "center", overflowWrap: "break-word" }}>{head}</td>
                //                         )
                //                     })
                //                 }
                //                 {
                //                     header.slice(-3).map((head, i) => {
                //                         return (
                //                             <td style={{ padding: "10px 6px 10px 6px", ...textClasses.boldText, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: "center", overflowWrap: "break-word" }}>{head}</td>
                //                         )
                //                     })
                //                 }
                //             </tr>
                //         </thead>
                //         <tbody data-testid='genericTable'
                //         >
                //             {
                //                 rows && rows.length > 0
                //                     ?
                //                     <>
                //                         {
                //                             rows.map((row, i) => (
                //                                 <tr
                //                                     key={i}
                //                                     style={{ borderBottom: "1px solid #0000002B" }}
                //                                 >
                //                                     {
                //                                         row.slice(0, 2).map((col, j) => {
                //                                             return (
                //                                                 <td style={{ textAlign: "left", padding: "7px 2px 7px 7px", ...textClasses.normalText }}>
                //                                                     {col}
                //                                                 </td>
                //                                             )
                //                                         })
                //                                     }
                //                                     {
                //                                         header.slice(2, 6).map((head, i) => {
                //                                             if (["Created At", "Updated At"].includes(head)) return null;
                //                                             return (
                //                                                 <td style={{ textAlign: "center", padding: "7px 2px 7px 7px", ...textClasses.normalText }}>
                //                                                     {row[i + 2]}
                //                                                 </td>
                //                                             )
                //                                         })
                //                                     }
                //                                     {
                //                                         row.slice(-3).map((col, j) => {
                //                                             return (
                //                                                 <td style={{ textAlign: "center", padding: "7px 2px 7px 7px", ...textClasses.normalText }}>
                //                                                     {col}
                //                                                 </td>
                //                                             )
                //                                         })
                //                                     }

                //                                 </tr>
                //                             ))
                //                         }

                //                     </>
                //                     :
                //                     ''



                //             }
                //         </tbody>

                //     </table>
                //     <Grid container alignItems={"center"} justifyContent="center">
                //         <TablePagination
                //             sx={{
                //                 ".MuiTablePagination-selectLabel": { pt: "10px" },
                //                 ".MuiTablePagination-displayedRows": { pt: "10px" }
                //             }}
                //             component="div"
                //             count={totalRows || 0}
                //             page={(filter.page || 1) - 1}
                //             onPageChange={(e, newPage) => setFilter({ ...filter, "page": newPage + 1,initial:false})}
                //             rowsPerPage={filter.limit}
                //             onRowsPerPageChange={(e) => setFilter({ ...filter, "page": 1, "limit": e.target.value,initial:false})}
                //         />



                //     </Grid>

                // </Grid>
                :
                <EmptyPage />
            }


        </>


    )
}
