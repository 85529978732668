import React, { useEffect, useState } from 'react'
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { Breadcrumbs, Grid, Tabs, Tab, Typography, Button, IconButton, Divider, Collapse, DialogContent, Dialog, TextField, FormLabel, FormControl, FormControlLabel, Radio, Autocomplete, TablePagination, Box, Checkbox } from '@mui/material'
import theme, { textClasses, buttonClasses, inputClasses } from "../../../static/styles/theme"
import GenericLoader from '../../Components/Generic/GenericLoader';
import { useDispatch, useSelector } from 'react-redux'
import GenericDetailRowData from "../../Components/Generic/GenericDetailRowData";
import { getSkuItemDetails } from '../../../redux/actions/InventoryJourney';
import Skeleton from '@mui/material/Skeleton';
import { DataNotFound } from '../../Components/Utils';


const ItemDetailsSection = ({ skuId, setTabIndex, fromDate, toDate }) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState("");
    const [initial, setInitial] = useState(true);
    const [itemDetailsData, setItemDetailsData] = useState({})
    const [itemDetailsTemplateHeader, setItemDetailsTemplateHeader] = useState([])
    // const { itemDetails={},tableHeader=[] } = useSelector((state) => state.InventoryJourney);  we can also use useSelector
    const { stockUnitData = {} } = useSelector((state) => state.InventoryJourney)

    const getItemDetailsHandler = () => {
        let payload = {
            SKU_ID: skuId
        }
        if (fromDate && toDate) {
            payload.fromDate = fromDate;
            payload.toDate = toDate;
        }
        setLoader("item-details")
        dispatch(getSkuItemDetails(payload, (data) => {
            if (data?.success) {
                setLoader("stock-details")
                setItemDetailsData(data?.data)
                // Modify the header to include fontSize for specific fields
                const modifiedHeader = data?.tableHeader.map(item => {
                    if (item.name === "SKU_ID" || item.name === "SKU_Detail") {
                        return {
                            ...item,
                            fontSize: "20px",
                            valueMarginLeft: "-50px",
                            headerMarginLeft: "-10px"
                        };
                    }
                    return item;
                });
                setItemDetailsTemplateHeader(modifiedHeader);
            }
            else {
                setLoader("")
            }
        }))
    }
    useEffect(() => {
        if (!initial) {
            setLoader("")
        }
        else {
            getItemDetailsHandler()
            setInitial(false)
        }

        setTimeout(() => {
            setLoader(""); // Clear loader after 3 seconds
        }, 2000);
    }, [skuId, stockUnitData])
    useEffect(() => {
        if (!initial) {
            getItemDetailsHandler()
        }
    }, [fromDate, toDate])
    return (
        <>
            {
                loader == "item-details" ? (
                    <Grid
                        data- testid="loader"
                        container
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems="center"
                        sx={{ p: 15 }
                        }
                    >
                        <Grid item>
                            <GenericLoader />
                        </Grid>
                    </Grid >
                ) :

                    itemDetailsData?._id ? <>
                        {/* <Typography
                            sx={{
                                ...textClasses.t15nBold,
                                m: 1,
                                display: "flex",
                                alignItems: "center",
                                width: '100%'
                            }}
                        >
                            Alert:&nbsp;
                            <marquee
                                direction="left"
                                scrollamount="8"
                                style={{
                                    whiteSpace: "nowrap",
                                    width: "calc(100% - 90px)",
                                    color: theme.themeRed,
                                }}
                            >
                                Next Replenishment Required after 40 days as per the projected inventory over time.
                            </marquee>
                        </Typography> */}
                        <Grid container m={2} spacing={2}>
                            {/* First Main Container Grid */}
                            <Grid item xs={12} sm={9} md={8} lg={6}>
                                <Box
                                    sx={{
                                        border: `1px solid ${theme.themeOrange}`,
                                        borderRadius: 2,
                                        padding: 2,
                                    }}
                                >
                                    <GenericDetailRowData
                                        detailData={itemDetailsData?.formData}
                                        cardHeader={itemDetailsTemplateHeader}
                                        loader={loader == "cardRowDetail"}
                                        data-testid="generic-data"
                                    />
                                </Box>
                            </Grid>

                            {/* Second Main Container Grid */}
                            <Grid container item xs={12} sm={3} md={4} lg={6} spacing={2}>
                                <Grid container item xs={12} spacing={2} data-testid="stock-container">
                                    <Grid item xs={12} lg={8}>
                                        <Box
                                            sx={{
                                                border: `1px solid ${theme.themeOrange}`,
                                                borderRadius: 2,
                                                padding: 3,
                                                backgroundColor: theme.themeLightOrange,
                                            }}
                                        >
                                            {/* Current Stock at the top center */}
                                            <Typography
                                                sx={{
                                                    ...textClasses.t16n,
                                                    textAlign: 'center',
                                                    color: theme.themeOrange,

                                                }}
                                            >{itemDetailsData?.currentStock || 0}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    ...textClasses.t16n,
                                                    textAlign: 'center',
                                                    color: theme.themeOrange,
                                                    mb: 2,
                                                }}
                                            >
                                                {itemDetailsData?.currentStock > 0 ? (itemDetailsData?.formData?.UOM || "") : ""} Current Stock
                                            </Typography>

                                            {/* Divider starting from the bottom of "Current Stock" */}
                                            {/* <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%', // Adjust this value to align with the bottom of "Current Stock"
                                                        left: '50%',
                                                        transform: 'translateX(-50%)',
                                                        height: '40%', // Adjust to your needs
                                                        borderLeft: `1px solid ${theme.themeOrange}`, // Vertical Divider
                                                    }}
                                                /> */}

                                            {/* On Hold on the left and Committed on the right */}
                                            <Grid container justifyContent="space-between" alignItems="center" p={1} spacing={1}>
                                                <Grid item sx={{ borderRight: `2px solid ${theme.themeOrange}` }} xs={6}>
                                                    <Typography sx={{ ...textClasses.t16n, color: theme.themeRed, textAlign: 'center', }}>
                                                        {itemDetailsData?.holdingCount || 0}
                                                    </Typography>
                                                    <Typography sx={{ ...textClasses.t16n, color: theme.themeRed, textAlign: 'center', }}>
                                                        {itemDetailsData?.holdingCount > 0 ? (itemDetailsData?.formData?.UOM || "") : ""} On Hold
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={6} >
                                                    <Typography sx={{ ...textClasses.t16n, color: theme.themeGreen, textAlign: 'center', }}>
                                                        {itemDetailsData?.comittedCount || 0}
                                                    </Typography>
                                                    <Typography sx={{ ...textClasses.t16n, color: theme.themeGreen, textAlign: 'center', }}>
                                                        {itemDetailsData?.comittedCount > 0 ? (itemDetailsData?.formData?.UOM || "") : ""} committed
                                                    </Typography>

                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>

                                    <Grid container item xs={12} lg={4}>
                                        {loader == "stock-details" ? <>
                                            <Box sx={{ width: 200 }}>
                                                <Skeleton />
                                                <Skeleton animation="wave" />
                                                <Skeleton animation={false} />
                                            </Box>
                                            <Box sx={{ width: 200 }}>
                                                <Skeleton />
                                                <Skeleton animation="wave" />
                                                <Skeleton animation={false} />
                                            </Box></>
                                            :
                                            <Grid item xs={12}
                                            data-testid="socket-data"
                                            >
                                                <Box
                                                    sx={{
                                                        border: `1px solid ${theme.themeGreen}`,
                                                        borderRadius: 2,
                                                        backgroundColor: theme.themeLightGreen,
                                                        padding: 2, // Padding inside this item
                                                        mb: 2
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            ...textClasses.t16n,
                                                            textAlign: 'center',
                                                            color: theme.themeGreen,

                                                        }}
                                                    >{stockUnitData?.inCount || 0}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            ...textClasses.t16n,
                                                            textAlign: 'center',
                                                            color: theme.themeGreen,
                                                        }}
                                                    > {stockUnitData?.inCount > 0 ? (itemDetailsData?.formData?.UOM || "") : ""} Inward</Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        border: `1px solid ${theme.themeGreen}`,
                                                        borderRadius: 2,
                                                        backgroundColor: theme.themeLightGreen,
                                                        padding: 2, // Padding inside this item
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            ...textClasses.t16n,
                                                            textAlign: 'center',
                                                            color: theme.themeGreen,

                                                        }}
                                                    >{stockUnitData?.outCount || 0}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            ...textClasses.t16n,
                                                            textAlign: 'center',
                                                            color: theme.themeGreen,
                                                        }}
                                                    >{stockUnitData?.outCount > 0 ? (itemDetailsData?.formData?.UOM || "") : ""} Outward</Typography>
                                                </Box>
                                            </Grid>
                                        }
                                    </Grid>


                                </Grid>

                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            border: `1px solid ${theme.themeOrange}`,
                                            borderRadius: 2,
                                            padding: 2, // Padding inside this item
                                        }}
                                    >
                                        <Typography sx={{
                                            ...textClasses.t15nBold,
                                            textAlign: 'left',
                                            color: theme.themeOrange,
                                            mb: 1,

                                        }}>Inventory Stock Planning</Typography>

                                        <Grid container spacing={1} data-testid="inv-stock-plan">
                                            <Grid item xs={6}>
                                                <Typography sx={{ ...textClasses.normalText }}>
                                                    Safety Stock :
                                                    <Button
                                                        sx={{
                                                            p: 0.3,
                                                            ml: 1,
                                                            cursor: "text !important",
                                                            ...buttonClasses.small,
                                                            color: theme.themeGreen,
                                                            backgroundColor: theme.themeLightGreen,
                                                            border: `1px solid ${theme.themeGreen}`,
                                                            "&:hover": { backgroundColor: theme.themeLightGreen },
                                                        }}
                                                    >
                                                        {itemDetailsData?.safetyStock || 0} {itemDetailsData?.safetyStock > 0 ? (itemDetailsData?.formData?.UOM || "") : ""}
                                                    </Button>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={{ ...textClasses.normalText }}>
                                                    Over Stock :
                                                    <Button
                                                        sx={{
                                                            p: 0.3,
                                                            ml: 1,
                                                            cursor: "text !important",
                                                            ...buttonClasses.small,
                                                            color: theme.themeGreen,
                                                            backgroundColor: theme.themeLightGreen,
                                                            border: `1px solid ${theme.themeGreen}`,
                                                            "&:hover": { backgroundColor: theme.themeLightGreen },
                                                        }}
                                                    >
                                                        {itemDetailsData?.overStock || 0} {itemDetailsData?.overStock > 0 ? (itemDetailsData?.formData?.UOM || "") : ""}
                                                    </Button>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ mt: 1 }} spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography sx={{ ...textClasses.normalText }}>
                                                    Lead Time :
                                                    <Button
                                                        sx={{
                                                            p: 0.3,
                                                            ml: 1,
                                                            cursor: "text !important",
                                                            ...buttonClasses.small,
                                                            color: theme.themeGreen,
                                                            backgroundColor: theme.themeLightGreen,
                                                            border: `1px solid ${theme.themeGreen}`,
                                                            "&:hover": { backgroundColor: theme.themeLightGreen },
                                                        }}
                                                    >
                                                        {itemDetailsData?.formData?.leadTime || 0}  {itemDetailsData?.formData?.leadTime > 0 ? "days" : ""}
                                                    </Button>
                                                </Typography>
                                            </Grid>
                                            {/* <Grid item xs={6}>
                                                <Typography sx={{ ...textClasses.normalText }}>
                                                    Order Fulfillment Time :
                                                    <Button
                                                        sx={{
                                                            p: 0.3,
                                                            ml: 1,
                                                            cursor: "text !important",
                                                            ...buttonClasses.small,
                                                            color: theme.themeGreen,
                                                            backgroundColor: theme.themeLightGreen,
                                                            border: `1px solid ${theme.themeGreen}`,
                                                            "&:hover": { backgroundColor: theme.themeLightGreen },
                                                        }}
                                                    >
                                                        {itemDetailsData?.formData?.fullfillmentTime || 0}  {itemDetailsData?.formData?.fullfillmentTime > 0 ? "days" : ""}
                                                    </Button>
                                                </Typography>
                                            </Grid> */}
                                        </Grid>
                                        <Grid container sx={{ mt: 1 }} spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography sx={{ ...textClasses.normalText }}>
                                                    Avg Demand :
                                                    <Button
                                                        sx={{
                                                            p: 0.3,
                                                            ml: 1,
                                                            cursor: "text !important",
                                                            ...buttonClasses.small,
                                                            color: theme.themeGreen,
                                                            backgroundColor: theme.themeLightGreen,
                                                            border: `1px solid ${theme.themeGreen}`,
                                                            "&:hover": { backgroundColor: theme.themeLightGreen },
                                                        }}
                                                    >
                                                        {itemDetailsData?.formData?.avgCount || 0}
                                                        {itemDetailsData?.formData?.avgCount > 0 ? ` ${itemDetailsData?.formData?.UOM || ""}/day` : ""}                                                    </Button>
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        {/* <Typography sx={{
                                            ...textClasses.t15nBold,
                                            textAlign: 'left',
                                            color: theme.themeOrange,
                                            mt: 1
                                        }}>ABC Analysis

                                        </Typography>
                                        <Grid container sx={{ mt: 1 }} spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography sx={{ ...textClasses.normalText }}>
                                                    Current category :
                                                    <Button
                                                        sx={{
                                                            p: 0.3,
                                                            ml: 1,
                                                            cursor: "text !important",
                                                            ...buttonClasses.small,
                                                            color: theme.themeGreen,
                                                            backgroundColor: theme.themeLightGreen,
                                                            border: `1px solid ${theme.themeGreen}`,
                                                            "&:hover": { backgroundColor: theme.themeLightGreen },
                                                        }}
                                                    >
                                                        A
                                                    </Button>
                                                </Typography>
                                            </Grid>
                                        </Grid> */}

                                        <Grid container spacing={1} sx={{ mt: 1, }} data-testid="stock-available">
                                            <Grid item xs={6}>
                                                <Typography sx={{
                                                    ...textClasses.t15nBold,
                                                    textAlign: 'left',
                                                    color: theme.themeOrange,
                                                }}>Stock Availability Forecast

                                                </Typography>

                                            </Grid>
                                            {/* <Grid item xs={6}>
                                                <Typography sx={{
                                                    ...textClasses.normalText,
                                                    textAlign: 'right',
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer'
                                                }}
                                                    onClick={() => { setTabIndex(2) }}
                                                >view inventory projection  </Typography>
                                            </Grid> */}
                                        </Grid>
                                        <Grid container sx={{ mt: 1 }} spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography sx={{ ...textClasses.normalText }}>
                                                    Stock Out In Next :
                                                    <Button
                                                        sx={{
                                                            p: 0.3,
                                                            ml: 1,
                                                            cursor: "text !important",
                                                            ...buttonClasses.small,
                                                            color: theme.themeGreen,
                                                            backgroundColor: theme.themeLightGreen,
                                                            border: `1px solid ${theme.themeGreen}`,
                                                            "&:hover": { backgroundColor: theme.themeLightGreen },
                                                        }}
                                                    >
                                                        {itemDetailsData?.stockOutInNext || 0} {itemDetailsData?.stockOutInNext > 0 ? "days" : ""}
                                                    </Button>
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </> :
                        <Grid container justifyContent="center" alignItems="center" sx={{ p: 4 }}>
                            <DataNotFound label="No data found for this Item." />
                        </Grid>
            }
        </>
    )
}

export default ItemDetailsSection;





