import React, { useEffect, useState } from 'react'
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { Breadcrumbs, Grid, Tabs, Tab, Typography, Button, IconButton, Divider, Collapse, DialogContent, Dialog, TextField, FormLabel, FormControl, FormControlLabel, Radio, Autocomplete, TablePagination, Box, Checkbox } from '@mui/material'
import theme, { textClasses, buttonClasses, inputClasses } from "../../../static/styles/theme"

const ProjectionSection = () => {
    return(<>
<Grid container justifyContent="center" alignItems="center">
    <Grid item>
        <Box
            sx={{
                width: '200px',        
                height: '100px',       
                backgroundColor: '#f5f5f5', 
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: `1px solid ${theme.themeOrange}`, 
                borderRadius: '8px',
                m:4   
            }}
        >
            <Typography sx={{ ...textClasses.t16n }}>
                Projections
            </Typography>
        </Box>
    </Grid>
</Grid>

    </>)
}

export default ProjectionSection;