import React, { useState, useCallback } from 'react';
import { Breadcrumbs, Grid, RadioGroup, Tab, Typography, Button, IconButton, Divider, Collapse, DialogContent, Dialog, TextField, FormLabel, FormControl, FormControlLabel, Radio, Autocomplete, TablePagination, Box, Checkbox } from '@mui/material'
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { makeStyles } from "@mui/styles";
import theme, { textClasses, buttonClasses, inputClasses } from "../../../static/styles/theme"
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import ClearIcon from "@mui/icons-material/Clear";
import { showToast } from "../../../services/functions";

const useStyles = makeStyles({
    datePickerWrapper: {
        ".datePickerWrapper-1 MuiBox-root css-0": {
            with: "30%",
        },

        "& .react-datepicker-wrapper": {
            width: "100%",
            "& .react-datepicker__close-icon::after": {
                backgroundColor: "#FF7200",
                right: "30px",
                position: "absolute",
                top: "9px",
            },
            "& .react-datepicker__input-container .react-datepicker__calendar-icon": {
                right: "0px",
                "& path": {
                    fill: "grey !important",
                },
            },
        },
    },
    datePickerContainer: {
        // border: "1px solid #FF7200",
        minHeight: "35px",
        minWidth: "100%",
        ...inputClasses.filterField,
        backgroundColor: "white",
        boxShadow: "none",
        "&::placeholder": {
            color: "#455A64",
            fontWeight: "300",
        },
        // "&:hover": {
        //     border: "1px solid white",
        // },
    },
});

let getToDate = () => {
    let current1 = dayjs().startOf('day'); // Start of the current day (no time)
    let fromDate = current1.subtract(7, "day"); // Subtract 7 days for the "From Date"
    let toDate = current1.endOf('day'); // End of the current day for "To Date"

    let date = [fromDate, toDate];
    return date;
};

const GenericGraphTimer = ({ id, title, options, onChange, children, selectedDays }) => {
    const [selectedValue, setSelectedValue] = useState(selectedDays);
    const classes = useStyles();
    const [dateRange, setDateRange] = useState(getToDate);

    const handleDateChange = useCallback(
        (date, type) => {
            let tem_data = [];
            if (type === "from") {
                if (!date) {
                    tem_data = [null, dateRange[1] || null];
                } else if (date.isAfter(dateRange[1])) {
                    showToast("Start Date must be smaller than End Date", true);
                    tem_data = [null, dateRange[1] || null];
                } else {
                    tem_data = [date.startOf('day'), dateRange[1] || null]; // Ensure no time part
                }
            } else { // if (type === "to")
                if (!date) {
                    tem_data = [dateRange[0] || null, null];
                } else if (date.isBefore(dateRange[0])) {
                    showToast("End Date must be Greater than Start Date", true);
                    tem_data = [dateRange[0] || null, null];
                } else {
                    let tempEndDate = date.endOf('day'); // End of the day for "To Date"
                    tem_data = [dateRange[0] || null, tempEndDate];
                }
            }
            setDateRange(tem_data);
        },
        [dateRange]
    );

    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);

        let current1 = dayjs().startOf("day"); // Current day, no time
        let fromDate;
        let toDate = current1.endOf("day");

        // Update date range based on selection
        if (newValue === "last7Days") {
            fromDate = current1.subtract(7, "day");
        } else if (newValue === "last15Days") {
            fromDate = current1.subtract(15, "day");
        } else if (newValue === "lastYear") {
            fromDate = current1.subtract(1, "year");
        } else {
            fromDate = dateRange[0];
        }

        // Set the calculated date range
        setDateRange([fromDate, toDate]);

        if (onChange) {
            onChange(id, newValue, [fromDate, toDate]); // Pass both the id, selected value, and date range
        }
    };

    return (
        <>
            <Box sx={{ m: 1 }}>
                <Typography sx={{ fontSize: "16px", color: "#FF7200", ml: 2 }}>
                    {title}
                </Typography>
                <FormControl sx={{ ml: 3 }}>
                    <RadioGroup
                        row
                        aria-labelledby={`generic-graph-timer-group-${id}`}
                        name={`generic-graph-timer-group-${id}`}
                        value={selectedValue}
                        onChange={handleChange}
                    >
                        {options.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                sx={{
                                    color: "#000",
                                    "& .MuiFormControlLabel-label": {
                                        fontSize: "12px",
                                    },
                                }}
                                value={option.value}
                                control={
                                    <Radio
                                        size="small"
                                        sx={{
                                            "&.Mui-checked": {
                                                color: "#FF7200",
                                            },
                                        }}
                                    />
                                }
                                label={option.label}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </Box>
            <Grid container lg={12}>
                {selectedValue === "custom" && (
                    <Grid container item lg={12} gap={1}>
                        <Grid item lg={5}>
                            <DatePicker
                                className={`${classes.datePickerContainer}`}
                                slotProps={{ textField: { size: "small" } }}
                                placeholderText="From Date"
                                label="From Date"
                                value={dateRange[0] || null}
                                onChange={(date) => handleDateChange(date, "from")}
                            />
                            {dateRange[0] && (
                                <ClearIcon
                                    sx={{
                                        color: "gray",
                                        position: "absolute",
                                        top: 6,
                                        right: 30,
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handleDateChange("", "from")}
                                />
                            )}
                        </Grid>

                        <Grid item lg={5}>
                            <DatePicker
                                className={`${classes.datePickerContainer}`}
                                value={dateRange[1] || null}
                                onChange={(date) => handleDateChange(date, "to")}
                                label="To Date"
                                slotProps={{ textField: { size: "small" } }}
                                placeholderText="To Date"
                            />
                            {dateRange[1] && (
                                <ClearIcon
                                    sx={{
                                        color: "grey",
                                        position: "absolute",
                                        top: 6,
                                        right: 30,
                                    }}
                                    onClick={() => handleDateChange("", "to")}
                                />
                            )}
                        </Grid>
                    </Grid>
                )}

                <Grid item lg={5} ml={2}>
                    {children}
                </Grid>
            </Grid>
        </>
    );
};

export default GenericGraphTimer;
