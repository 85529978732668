import React, { useEffect } from 'react'
import { Typography, Grid } from '@mui/material'
import theme from "../../../static/styles/theme"

import GenericComponentsCollapse from '../../Components/Generic/GenericComponentsCollapse'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTemplates } from '../../../redux/actions'
import { preDefineElement } from './componentList'

export default function StandardComponents({stdComponents, predefinedElements}) {

    const dispatch = useDispatch();
    const templates = useSelector(state => state.templates.allTemplates);
    const freeTemplates = templates.filter(t => t.templateType == "freeTemplate")
    // ////('Free Templates');
    // ////(freeTemplates);
    ////("Standard Components");
    ////(stdComponents);

    useEffect(() => {
        dispatch(getAllTemplates({
            type: "wms",
            projection: { attributes: 1, dataEntryType: 1, name: 1, templateCode: 1, templateType: 1, },
            templateType: "freeTemplate",
            status:"published"
        }));
    }, [])
//console.log(freeTemplates, "show freeTemplates")

    return (
        <Grid container sx={{zIndex: 100, mr: 2,overflowY: "auto", maxHeight:'75vh'}}>
            <Grid item sm={12}>
                <Typography variant='h6' sx={{color: theme.themeOrange}}>Standard Form Component</Typography>
                <Typography variant='caption'>
                    Drag a field type to one of the sections on the left to create a custom field for this issue type.
                </Typography>
                
                {
                    stdComponents && stdComponents.filter(section => ["Section Breaker", "Grid"].indexOf(section._id) < 0).map((section, i) => {
                        return (
                            <GenericComponentsCollapse
                                key={i}
                                index={i+1}
                                title={section._id}
                                components={section.elements}                            
                            />
                        )
                    })
                }
               {predefinedElements?.elements?.length > 0 && <GenericComponentsCollapse
                    key={3}
                    index={3+1}
                    title={predefinedElements._id}
                    type="list"

                    components={predefinedElements.elements}
                />}
               {freeTemplates?.length > 0 && <GenericComponentsCollapse
                    key={4}
                    index={4+1}
                    title={"Free Templates"}
                    type="list"
                    components={freeTemplates}
                />}
            </Grid>
            
            
        </Grid>
    )
}

const textComponents = [
    {
        "_id": "62beeacf20037f12772790b7",
        "type": "input",
        "icon": "simple-icon-note",
        "label": "Input",
        "description": "Enter your input",
        "placeholder": "Enter your input placeholder",
        "className": "form-control",
        "subtype": "text",
        "regex": "",
        "handle": true,
        "forType": "all",
        "elementType": "Text and input",
        "sortType": 1,
        "value": ""
    },
    {
        "_id": "62beeacf20037f12772790b8",
        "type": "number",
        "icon": "simple-icon-note",
        "label": "Number",
        "description": "Enter your number",
        "placeholder": "Enter your number placeholder",
        "className": "form-control",
        "subtype": "text",
        "regex": "^[0-9]*$",
        "handle": true,
        "forType": "all",
        "elementType": "Text and input",
        "sortType": 1
    },
    {
        "_id": "62beeacf20037f12772790b9",
        "type": "date",
        "icon": "simple-icon-calendar",
        "label": "Date",
        "placeholder": "Date",
        "className": "form-control datepicker",
        "subtype": "text",
        "forType": "all",
        "elementType": "Text and input",
        "sortType": 1
    },
    {
        "_id": "62beeacf20037f12772790ba",
        "type": "textarea",
        "icon": "iconsmind-Text-Box",
        "label": "Long Text",
        "className": "form-control",
        "forType": "all",
        "elementType": "Text and input",
        "sortType": 1
    }
]

const selectComponents = [
    {
        "_id": "62beeacf20037f12772790bb",
        "type": "select",
        "icon": "simple-icon-menu",
        "label": "Dropdown",
        "description": "Select",
        "placeholder": "Select",
        "className": "form-control select2-single",
        "values": [
            {
                "label": "Option 1",
                "value": "option 1"
            },
            {
                "label": "Option 2",
                "value": "option 2"
            },
            {
                "label": "Option 3",
                "value": "option 3"
            }
        ],
        "subtype": "single",
        "forType": "all",
        "elementType": "Selection Type",
        "sortType": 2
    },
    {
        "_id": "62beeacf20037f12772790bd",
        "type": "checkbox",
        "required": false,
        "label": "Checkbox",
        "icon": "simple-icon-list",
        "description": "Checkbox",
        "inline": true,
        "values": [
            {
                "label": "Option 1",
                "value": "option 1"
            },
            {
                "label": "Option 2",
                "value": "option 2"
            },
            {
                "label": "Option 3",
                "value": "option 3"
            }
        ],
        "forType": "all",
        "elementType": "Selection Type",
        "sortType": 2,
        "value": []
    },
    {
        "_id": "62beeacf20037f12772790be",
        "type": "radio",
        "icon": "iconsmind-Bulleted-List",
        "label": "Radio",
        "description": "Radio boxes",
        "values": [
            {
                "label": "Option 1",
                "value": "option 1"
            },
            {
                "label": "Option 2",
                "value": "option 2"
            },
            {
                "label": "Option 3",
                "value": "option 3"
            }
        ],
        "forType": "all",
        "elementType": "Selection Type",
        "sortType": 2
    }
]

const uploadComponents = [
    {
        "_id": "62bef1b020037f12772790c3",
        "type": "heading",
        "icon": "iconsmind-Text-Paragraph",
        "label": "Large Heading",
        "placeholder": "Heading",
        "elementType": "Section Breaker",
        "sortType": 4
    },
    {
        "_id": "62bef1b020037f12772790c4",
        "type": "paragraph",
        "icon": "iconsmind-Normal-Text",
        "label": "Small Text",
        "placeholder": "Text",
        "className": "custom_para text-center",
        "elementType": "Section Breaker",
        "sortType": 4
    }
]

const sectionComponents = [
    {
        "_id": "62bef1b020037f12772790c3",
        "type": "heading",
        "icon": "iconsmind-Text-Paragraph",
        "label": "Large Heading",
        "placeholder": "Heading",
        "elementType": "Section Breaker",
        "sortType": 4
    },
    {
        "_id": "62bef1b020037f12772790c4",
        "type": "paragraph",
        "icon": "iconsmind-Normal-Text",
        "label": "Small Text",
        "placeholder": "Text",
        "className": "custom_para text-center",
        "elementType": "Section Breaker",
        "sortType": 4
    }
]
