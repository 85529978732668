

export const dynamicComponent = ({type = "customer",filter = [], connectedWithAddressBook }) => {
    // Define the common settings for both customer and supplier types
    let commonSettings = connectedWithAddressBook?.at(0) || {};
    if(commonSettings!={}){
        commonSettings=JSON.parse(JSON.stringify(commonSettings))
        commonSettings?.apiParameters?.push(...filter)
    }

    // Specific settings based on the type parameter
    const typeSpecificSettings = {
        customer: {
            _id: "customerID",
            apiEndPoint: "bussiness/getBussinessPartnersList",
        },
        supplier: {
            _id: "supplierID",
            apiEndPoint: "bussiness/getBussinessPartnersList",
        }
    };


    // Merge the common settings with the type-specific settings
    return {
        ...commonSettings,
        ...typeSpecificSettings[type]
    };
};

export const component = ({ type = "customer", optional = true, filter = [] }) => {
    // Define the common settings for both customer and supplier types
    const commonSettings = {
        type: "select",
        description: type.charAt(0).toUpperCase() + type.slice(1),
        placeholder: `${type.charAt(0).toUpperCase() + type.slice(1)}${optional ? "(Optional)" : ""}`,
        elementType: "Selection Type",
        value: "",
        values: [],
        dynamic: true,
        required: !optional,
        auth: true,
        keyForValue: "blink_id",
        keyForLabel: "company",
        apiDomain: "",
        extraValue: ["all"],
        baseURL: "",
        apiMethod: "post",
        apiDataReturnKey: "data",
        apiParameters: [
            ...filter,
            { label: "searchBy", value: "company_name" },
            { label: "page", value: 1 },
            { label: "limit", value:25},
            { label: "search", value: "onSearch" }
        ],
        setTargetValue: false,
    };

    // Specific settings based on the type parameter
    const typeSpecificSettings = {
        customer: {
            _id: "customerID",
            label: "Customer",
            apiEndPoint: "bussiness/getBussinessPartnersList",
            name: "Customer List"
        },
        supplier: {
            _id: "supplierID",
            label: "Supplier",
            apiEndPoint: "bussiness/getBussinessPartnersList",
            name: "Supplier List"
        }
    };

    // Merge the common settings with the type-specific settings
    return {
        ...commonSettings,
        ...typeSpecificSettings[type]
    };
};


export const customerMasterVisibilityFields = [
    { label: "Company Name", name: "company", type: "input", },
    {
        label: "Address", name: "address", type: "input",
        renderOption: [
            {
                "label": "",
                "multiple": false,
                "prefix": "",
                "postfix": "",
                "key": "address",
                "keys": [

                ]
            },
            {
                "label": "",
                "multiple": false,
                "prefix": ", ",
                "postfix": "",
                "key": "city.name"
            },
            {
                "label": "",
                "multiple": false,
                "prefix": ", ",
                "postfix": "",
                "key": "state.name"
            },
        ]
    },
    { label: "Address Type", name: "address_sub_type", type: "input"},
    { label: "Branch Name", name: "address_type", type: "input",splitWord:true,splitOn:"[\/#@&\-_\s]+",joinWith:" ",formatWord:"toCapitalize" },
    { label: "Account Type", name: "account_type", type: "input",formatWord:"toCapitalize" },
    { label: "City", name: "city.name", type: "input", },
    { label: "State", name: "state.name", type: "input", },
]

export const customerMasterFields =[
    { label: "Company Name", name: "company", type: "input", },
    { label: "Address", name: "address", type: "input", },
    { label: "Address Type", name: "address_sub_type", type: "input" },
    { label: "Branch Name", name: "address_type", type: "input" ,splitWord:true,splitOn:"[\/#@&\-_\s]+",joinWith:" ",formatWord:"toCapitalize" },
    { label: "Account Type", name: "account_type", type: "input",formatWord:"toCapitalize" },
    { label: "City", name: "city.name", type: "input", },
    { label: "State", name: "state.name", type: "input", },
]

