import AxiosInatance from "axios";
import config from "./config";
import { renderOptionHandler,deepCopy_Object } from "./functions";
function getConnection(base_URL,passToken=null){
    let connection= AxiosInatance.create({
      "baseURL": base_URL|| config.baseURL ||"/v1/",
      "headers": {
        "Content-Type": "application/json",
        "Authorization":JSON.parse(localStorage.getItem("token")),
        "Access_type":"service",
        "Project":"WMS",
        ...(passToken?{Partialaccesstoken:passToken}:{})
      }
    });
    // console.log("baseURL>>>>>>",base_URL)

    return connection

}

export const calculateTarget = (rowElement,item, data = null) => {
    for (let key in item.onChangeSet) {
        let value;
        let keyArr = []
        if (item.onChangeSet[key].includes("$")) {
            keyArr = item.onChangeSet[key].split(".")
            if (keyArr[0] == '$response') {
                value = data
            }
            else {
                if (item.type == 'select' && item.values && item.values.length) {
                    value = item.values.find(el => el[item.apiBindingValue ? item.apiBindingValue : "value"] == item.value)
                }
                else {
                    value = item.value
                }
            }
            keyArr = keyArr.slice(1)
        }
        else {
            value = data
            keyArr = item.onChangeSet[key].split(".")
        }
        for (let key of keyArr) {
            if(value){
                value = value[key]
            }
            else{
                value=""
                break;
            }
        }
        setTarget(rowElement,key, value)
    }
}

export const setTarget = (rowElement,key, data="",skipSplit=true) => {
    if(skipSplit){
        rowElement[key]=data
        if(!rowElement["disabled"])rowElement["disabled"]=[]
        if(data?.length>0){
            rowElement["disabled"].push(key)
        }
    }
    else{
        let new_target = key.split("-")
        if (new_target.length == 1) {
            rowElement[key]=data
        }
        else {
            rowElement[key]={[new_target[1]]:data }
        }
        if(!rowElement["disabled"])rowElement["disabled"]=[]
        if(data?.length>0){
            rowElement["disabled"].push(new_target.pop())
        }

    }
}
export const calculateTargetAPIParams = (item={},valueIn,type="apiParameters") => {
    let obj={},value=valueIn && typeof valueIn=="string" && valueIn.split("(")?.at(0) || valueIn;
    // let obj={};
    if (type=="apiParameters" && item.apiParameters && item.apiParameters.length) {
        let $self = ''
        if (item.type == 'select' && item.values && item.values.length) {
            $self = item.values.find(el => el[item.apiBindingValue ? item.apiBindingValue : "value"] == value) || {}
        }
        else {
            $self = value
        }

        item.apiParameters.forEach(el => {
            if (el.value && typeof el.value!="number" && (el.value+"").includes("$self")) {
                let value = $self
                let keyArr = el.value.split(".")
                keyArr = keyArr.slice(1)
                for (let key of keyArr) {
                    value = value[key]
                }
                appendData(value, el.label)
            }
            else if(el.value=="onSearch") {
                appendData(value, el.label)
            }
            else {
                appendData(el.value == 'self' ? value : el.value, el.label)
            }
            // if (el.value) {
            // }
        });
    }
    else if (type=="targetParameters" && item.targetParameters && item.targetParameters.length) {
        let $self = ''
        if (item.type == 'select' && item.values && item.values.length) {
            $self = item.values.find(el => el[item.apiBindingValue ? item.apiBindingValue : "value"] == value) || {}
        }
        else {
            $self = value
        }

        item.targetParameters.forEach(el => {
            if (el.value && typeof el.value!="number" && (el.value+"").includes("$self")) {
                let value = $self
                let keyArr = el.value.split(".")
                keyArr = keyArr.slice(1)
                for (let key of keyArr) {
                    value = value[key]
                }
                appendData(value, el.label)
            }
            else {
                appendData(el.value == 'self'  ? value : el.value, el.label)
            }
            // if (el.value) {
            // }
        });
    }

    function appendData(value, label = '') {
        let temp_method="apiMethod"
        if(type=="targetParameters"){
            temp_method="targetAPIMethod"
        }
        if (item[temp_method] == 'get') {
            if (!obj) obj = []
            obj.push(encodeURIComponent(label) + '=' + encodeURIComponent(value))
        }
        else if (item[temp_method] == 'get_URI') {
            if (!obj) obj = ''
            obj += encodeURIComponent(value)
        }
        else {
            if (!obj) obj = {}
            obj[label] = value
        }
    }

    return obj
}

export const handleDynamicChange = async(rowElement={},item={},option={},storeDetailIn="",extraReadFormResponse=[]) => {//itemDetail
    if(item.setTargetValue && item.targetAPI){
        await customAPI_For_TargetAPIPara(item,option,extraReadFormResponse,rowElement,(response)=>{
            try {
              
                if (item.onChangeSet) {
                    calculateTarget(rowElement,item, response || null)
                }
                else if(item.targetElement){
                    let keyArr=item.targetElement.split(".")
                    let value=""
                    if(keyArr.length==1){
                        value=response[item.targetElement]
                    }
                    else{
                        for (let key of keyArr) {
                            if(value){
                                value = value[key]
                            }
                            else{
                                value=""
                                break;
                            }
                        }
                    }
                    
                    setTarget(rowElement,item.targetElement, value)
                }
                if(storeDetailIn){
                    rowElement[storeDetailIn]=response || null
                }
            }
            catch (e) {
                console.log(e.message);              
            }
        })  
    }
    else if (item.setTargetValue) {
        if (item.onChangeSet) {
            try {
                calculateTarget(rowElement,item)
            }
            catch (e) {
                console.log(e.message);         
            }
        }
    }
}

export const customAPI_For_APIPara = async(item={},value,cb) => {
    let params=calculateTargetAPIParams(deepCopy_Object(item),value,"apiParameters")
    const {passToken=null,apiEndPoint,apiDataReturnKey,keyForLabel,keyForValue,suffix="",extraValue=[],baseURL="",renderOption=[]}=item
    // console.log("baseURL",baseURL)
    let connection=getConnection(baseURL,passToken)
    // console.log("connection",connection)
    await connection.post(`/${apiEndPoint}`, {...params}).then(({data}) => {
        if(!data.success && !data.error) data["success"]=true
        if(data.success){
            let allData=data[apiDataReturnKey || "data"] || []
            let values=allData.map((dl)=>{
                
                let label="",value="",renderOptionEnable=false
                if(Array.isArray(keyForLabel)){
                    for(let key of keyForLabel){
                        if(dl[key]){
                            label=dl[key]
                            break;
                        }
                    }
                }
                else{
                    if(Array.isArray(renderOption) && renderOption?.length>0){
                        label=renderOptionHandler(dl,renderOption)
                        renderOptionEnable=true
                    }
                    else{
                        label=dl[keyForLabel] || ""
                    }
                }
                if(Array.isArray(keyForValue)){
                    for(let key of keyForValue){
                        value=dl[key]
                        break;
                    }
                }
                else{
                    value=dl[keyForValue] || ""
                }
                
                if(!renderOptionEnable && suffix && dl[suffix]){
                    label=label +"("+ dl[suffix] +")"
                }
                let obj={label,value}

                if(extraValue?.length){
                    if(extraValue.includes("all")){
                        let {id,_id,...rest}=dl
                        Object.assign(obj, rest);
                    }
                    else{
                        for(let key of extraValue){
                            obj[key]= dl[key] || ""
                        }
                    }
                }
                return obj
            })
            // console.log("values",values)
            cb(values)
        }
        else{
            cb([])
        }
    }).catch((error) => {
        //("ERROR", error);
        cb([])
    })
}


export const customAPI_For_TargetAPIPara = async(item={},option,extraReadFormResponse=null,rowElement={}, cb) => {
    let params=calculateTargetAPIParams(deepCopy_Object(item),option,"targetParameters")
    const {passToken=null,targetAPI,targetAPIMethod,targetApiDataReturnKey,baseURL}=item
    let connection=getConnection(baseURL,passToken)
    await connection.post(`/${targetAPI}`, {...params}).then(({data}) => {
        if(!data.success && !data.error) data["success"]=true
        if(data.success){
            let dataObj=data[targetApiDataReturnKey || "data"] || {}
            if(extraReadFormResponse?.length>0 && Array.isArray(extraReadFormResponse)){

                for(let ele of extraReadFormResponse){
                    if(data[ele]){
                        rowElement[ele]=data[ele]
                    }
                }
                cb(dataObj)
            }
            else{
                cb(dataObj)
            }
        }
        else{
            cb(null)
        }
    }).catch((error) => {
        cb(null)
    })
}

